import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import * as modals from "../Structure/LayoutModals";
import DisplayList from '../Structure/DisplayList';

export class ModelList extends Component {
    static displayName = "ModelList";

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showModal: false,
            models: [],
            languages: [],
            id_language: 0,
            categories: [],
            id_categorie: 0,
            organizations:[],
            id_organization: -1,
            selectedModel: null,
            selectedModalAction: '',
            modelName: '',
            url: ' ',
            previewContent: ''
        };
        this.fetchDataOnLoadAPI = this.fetchDataOnLoadAPI.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeUrl = this.handleChangeUrl.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
        this.handleChangeCategorie = this.handleChangeCategorie.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.fetchDataOnLoadAPI();
    }

    async fetchDataOnLoadAPI() {
        let languagesResult = await utils.GetLanguages();
        let categoriesResult = await utils.GetModelCategories();
        let orgs = await utils.GetOrganizations();
        this.setState({
            models: await utils.GetModels(false),
            languages: languagesResult,
            id_language: languagesResult[0]?.languageId ?? 6,
            categories: categoriesResult,
            id_categorie: categoriesResult[0]?.id ?? 0,
            organizations: orgs,
            id_organization: orgs[0]?.id ?? 0,
            loading: false
        })
    }

    handleChangeLanguage(e) {
        this.setState({ id_language: e.target.value });
    }
    handleChangeCategorie(e) {
        this.setState({ id_categorie: e.target.value });
    }
    handleChangeName(e) {
        this.setState({ modelName: e.target.value });
    }
    handleChangeUrl(e) {
        this.setState({ url: e.target.value });
    }

    async handleModalOpen(modelObj, action, confirmDelete) {
        this.setState({
            showModal: true,
            selectedModel: modelObj,
            selectedModalAction: action
        })
        const updatedModels = [...this.state.models];

        switch (action) {
            case "preview":
                this.setState({
                    previewContent: await utils.GetExempleChampAuto(modelObj.content)
                })
                break;
            case ("deleteLanguage"):
                const modelIndex = updatedModels.findIndex(model => model.listModelLangue.some(lang => lang.id === modelObj.id));
                const model = updatedModels[modelIndex];
                if (modelIndex !== -1) {
                    const langIndex = model.listModelLangue.findIndex(lang => lang.id === modelObj.id);
                    if (langIndex !== -1) {
                        if (model.listModelLangue.length < 2 && confirmDelete === false) {
                            const modelToArchive = updatedModels.find(model => model.id === modelObj.idModelGeneral)
                            this.setState({
                                selectedModalAction: 'deleteModelLanguage',
                                selectedModel: modelToArchive
                            })
                            return;
                        }
                        if (modelObj.idLanguage === model.idLanguageDefault && confirmDelete === false) {
                            this.setState({selectedModalAction: 'deleteDefaultLanguage'})
                            return;
                        }
                        if (await utils.DeleteModelLangue(modelObj.id)) {
                            if (model.listModelLangue.length === 1) {
                                const modelIndex2 = updatedModels.indexOf(model);
                                updatedModels.splice(modelIndex2, 1);
                            }
                            else {
                                model.listModelLangue.splice(langIndex, 1);
                            }
                            this.setState({
                                models: updatedModels
                            });
                            this.handleModalClose();
                        }
                    }
                }

                break;
            case "archive":
                this.handleModalClose();
                let formData = new FormData();
                formData.append('Id', modelObj.id);
                formData.append('ModelName', null);
                formData.append('LanguageDefaultId', 0);
                formData.append('OrganizationId', 0);
                formData.append('CategoryId', 0);
                formData.append('Url', null);
                formData.append('IsArchived', true);
                formData.append('MessageContent', null);
                formData.append('LanguageId', 0);
                
                const modelToRemove = updatedModels.findIndex(obj => obj.id === modelObj.id);
                if (modelToRemove !== -1) {
                    if (await utils.UpdateModel(formData)) {
                        updatedModels.splice(modelToRemove, 1);
                    }
                    this.setState({
                        models: updatedModels
                    });
                }
                break;
            default:
                break;
        }
    }

    handleModalClose() {
        this.setState({
            showModal: false,
            selectedModel: null,
            selectedModalAction: ''
        })
    }
    
    handleSubmit(e) {
        e.preventDefault();
    }

    displayModal() {
        let modalTitle = null;
        let modalBody = null;
        let modalFooter = null;

        switch (this.state.selectedModalAction) {
            case "preview":
                modalTitle = <p>Prévisualisation du modèle</p>;
                modalBody = this.state.previewContent;
                return modals.displayLayoutModalNoFooter(this.state.showModal, this.handleModalClose, modalTitle, modalBody)
            case "deleteDefaultLanguage":
                modalTitle = <p>Voulez vous supprimer cette langue?</p>;
                modalBody = <p>il s'agit de la traduction par défaut</p>
                modalFooter = 
                <div>
                    <button className="btn btn-primary rounded-corners me-2" onClick={() => this.handleModal(this.state.selectedModel, "deleteLanguage", true)}>Supprimer</button>
                    <button className="btn btn-secondary rounded-corners" onClick={this.handleModalClose}>Fermer</button>
                </div>
                break;
            case "deleteModelLanguage":
                modalTitle = <p>Vous ne pouvez pas faire ça!</p>;
                modalBody = <p>Il s'agit du dernier élément de traduction du modèle,
                    vous ne pouvez pas supprimer la dernière traduction d'un modèle.
                    Voulez vous plutot transférer ce modèle dans les archives ?</p>
                modalFooter = 
                <div>
                    <button className="btn btn-success rounded-corners me-2" onClick={() => this.handleModal(this.state.selectedModel, "archive", false)}>Archiver</button>
                    <button className="btn btn-secondary rounded-corners" onClick={this.handleModalClose}>Fermer</button>
                </div>
                break;
            default:
                break;
        }
        return modals.displayLayoutModal(this.state.showModal, this.handleModalClose, modalTitle, modalBody, modalFooter)
    }

    render() {
        let modal = null;
        let content = null;

        this.state.selectedModel === null ? modal = null : modal = this.displayModal()

        if (this.state.loading) {
            content = <p><em>Loading...</em></p>
        }
        else {
            content = <DisplayList
                key={this.state.models.length }
                list={this.state.models}
                recordsType={"models"}
                handleModalOpen={this.handleModalOpen}
                languages={this.state.languages}
                pagination={true}
            />
        }

        return (
            <div>
                {content}
                {modal}
            </div>
        );
    }
}