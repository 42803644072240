import React, { Component } from 'react';
import { ModelAddEdit } from './ModelForm';

export class ModelAdd extends Component {
    static displayName = "ModelAdd";

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <ModelAddEdit action="add" />
        );
    }
}

