import { React } from 'react';

function SearchResultData(props) {

    function selectYearFunc() {
        return (
            <div className="col-sm-3 mt-3">
                <nav>
                    <ul className='pagination justify-content-center'>
                        <li className="page-item">
                            <button className="page-link"
                                onClick={() => props.handleSelectedYear(props.selectedYear - 1)} >
                                <b>&#60;</b>
                            </button>
                        </li>
                        <li>
                            <div className='page-link active'>
                                {props.selectedYear}
                            </div>
                        </li>
                        <li className="page-item">
                            <button className="page-link"
                                onClick={() => props.handleSelectedYear(props.selectedYear + 1)}>
                                <b>&#62;</b>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }

    let selectYear = null;
    props.enableYearSelection ? selectYear = selectYearFunc() : selectYear = null;

    return (
        <div>
            <div className="row">
                <div className="col-sm-3 ms-2">
                    Nombre de résultat à afficher par page :
                    <select className="form-select" value={props.recordsPerPage}
                        onChange={props.handleChangeRecordsPerPage} >
                        <option value="15">15 résultats</option>
                        <option value="25">25 résultats</option>
                        <option value="50">50 résultats</option>
                        <option value="100">100 résultats</option>
                    </select>
                </div>
            
                {selectYear}
            </div>
            <div className="col-sm-3">
                Nombre de résultat trouvé: {props.numberOfResult }
            </div>
        </div>
    )
}

export default SearchResultData