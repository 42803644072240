import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { showToast } from '../../Service/toast';

export class ModelAddEdit extends Component {
    static displayName = "ModelAddEdit";

    constructor(props) {
        super(props);
        this.state = {
            nbChar: 0,
            nbCharMax: 160,
            nbCharLeft: 0,
            nbUnite: 1,
            arrBtns: [
                { name: "Prénom", field: '{fn}'},
                { name: "Nom", field: '{ln}' },
                { name: "URL", field: '{url}' },
                { name: "Date d'arrivée", field: '{DA}'},
                { name: "Date de départ", field: '{DD}'},
            ],

            idModel: -1,
            categories: [],
            id_categorie: -1,
            languages: [],
            id_language: -1,
            id_default_language: -1,
            languageChanged: false,
            id_organization: -1,
            autoFieldArray: [],
            autoField: '',
            content: '',
            preview_content: '',
            model_name: '',
            url: '',
            specialChars: false,
            listModelLangue: []
        };
        this.handleChangeDefaultLanguage = this.handleChangeDefaultLanguage.bind(this);
        this.setContentFieldByLanguage = this.setContentFieldByLanguage.bind(this);
        this.fetchUpdateModel = this.fetchUpdateModel.bind(this);
        this.handleChangeAutoField = this.handleChangeAutoField.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
        this.handleChangeCategorie = this.handleChangeCategorie.bind(this);
        this.handleChangeContent = this.handleChangeContent.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeUrl = this.handleChangeUrl.bind(this);
        this.handleChangeSpecialChars = this.handleChangeSpecialChars.bind(this);
        this.fetchDataOnLoadAPI = this.fetchDataOnLoadAPI.bind(this);
        this.fetchPostModel = this.fetchPostModel.bind(this);
        this.fetchPreviewExampleStr = this.fetchPreviewExampleStr.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.fetchDataOnLoadAPI();
        this.setState({
            nbCharLeft: this.state.nbCharMax - this.state.nbChar
        })
    }
    componentDidUpdate() {
        if (this.state.autoField !== '') {
            this.setState({ content: this.state.content + this.state.autoField })
            this.setState({
                autoField: ''
            });
        }
        if (this.state.languageChanged) {
            this.setContentFieldByLanguage(this.state.listModelLangue, this.state.id_language);
            this.setState({
                languageChanged: false
            })
        }
    }

    handleChangeAutoField(field) {
        this.setState({ autoField: field });
    }
    handleChangeLanguage(e) {
        this.setState({
            id_language: Number(e),
            languageChanged: true
        });
    }
    setContentFieldByLanguage(listLanguages, idLanguage) {
        let langueUtilisee = listLanguages.find(x => x.languageId === idLanguage)
        if (langueUtilisee !== undefined) {
            this.setState({ content: langueUtilisee.content });
        }
        else {
            this.setState({ content: "" });
        }
    }
    handleChangeDefaultLanguage(e) {
        this.setState({ id_default_language: e.target.value });
    }
    handleChangeCategorie(e) {
        this.setState({ id_categorie: e.target.value });
    }
    handleChangeContent(e) {
        this.setState({ content: e.target.value });
    }
    handleChangeName(e) {
        this.setState({ model_name: e.target.value });
    }
    handleChangeUrl(e) {
        this.setState({ url: e.target.value });
    }
    handleChangeSpecialChars(){
        this.setState({ specialChars: !this.state.specialChars})
    }

    async fetchDataOnLoadAPI() {
        let categoriesResult = await utils.GetModelCategories();
        let languagesResult = await utils.GetLanguages();
        let idOrgResult = await utils.GetUserOrg();
        if (this.props.action === 'edit') {
            let idLanguageTemp;
            let idModel = this.props.modelId;
            let idLangu = this.props.languageId;
            let modelResult = await utils.GetModel(idModel);
            if (modelResult == null) {
                window.location.replace('/model');
                //replace this line by rendering a new modal with error message
            }
            if (modelResult.categoryId === null) {
                this.setState({ id_categorie: categoriesResult[0].id })
            }
            else {
                this.setState({ id_categorie: modelResult.categoryId })
            }

            if (idLangu !== null) {
                idLanguageTemp = Number(idLangu);
            }
            else {
                idLanguageTemp = modelResult.languageDefaultId;
            }
            this.setState({
                idModel: idModel,
                model_name: modelResult.modelName,
                url: modelResult.url,
                id_language: idLanguageTemp,
                id_default_language: modelResult.languageDefaultId,
                id_organization: modelResult.organizationId,
                listModelLangue: modelResult.listModelContent,
                languages: languagesResult,
                categories: categoriesResult,
            })
            this.setContentFieldByLanguage(modelResult.listModelContent, idLanguageTemp);
        }
        else {
            this.setState({
                categories: categoriesResult,
                id_categorie: categoriesResult[0].id,
                languages: languagesResult,
                id_default_language: languagesResult[0].languageId,
                id_organization: idOrgResult
            })
        }
    }

    async fetchPreviewExampleStr() {
        this.setState({
            preview_content: await utils.GetExempleChampAuto(this.state.content)
        });
    }

    async fetchPostModel() {
        let formData = new FormData();
        if (this.state.model_name !== '') {
            formData.append('ModelName', this.state.model_name);
        } else {
            showToast('Veuillez renseignez un nom', 300)
            return;
        }

        if (this.state.content !== '') {
            formData.append('MessageContent', this.state.content);
        } else {
            showToast('Le champs contenu est vide', 300)
            return;
        }

        formData.append('OrganizationId', this.state.id_organization);
        formData.append('CategoryId', this.state.id_categorie);
        formData.append('Url', this.state.URL);
        formData.append('LanguageDefaultId', this.state.id_default_language);
        formData.append('IsArchived', false);
        formData.append('LanguageId', this.state.id_default_language);
                
        if (await utils.PostModel(formData)) {
            window.location.replace('/model');
        }
    }

    async fetchUpdateModel() {
        let formData = new FormData();
        formData.append('Id', this.state.idModel);
        formData.append('LanguageId', this.state.id_language);
        formData.append('LanguageDefaultId', this.state.id_default_language);
        formData.append('Url', this.state.URL);
        formData.append('CategoryId', this.state.id_categorie);
        if (this.state.model_name !== '') {
            formData.append('ModelName', this.state.model_name);
        } else {
            showToast('Veuillez renseignez un nom', 300)
            return;
        }
        if (this.state.content !== '') {
            formData.append('MessageContent', this.state.content);
        } else {
            showToast('Le champs contenu est vide', 300)
            return;
        }

        this.setState({
            isOpen: false
        });
        
        if (await utils.UpdateModel(formData)) {
            window.location.replace('/model');
        }
    }

    renderTooltip(props) {
        return (
            <Tooltip id="button-tooltip" {...props}>
                Caratères spéciaux : @£$¥èéùìòÇØøÅçåΔ_ΦΓΛΩΠΨΣΘΞ^{ }[~]|€ÆæßÉ!#¤%&'()*¡ÄÖÑÜ§¿äöñüà
            </Tooltip>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.props.action === 'add') {
            this.fetchPostModel();
        }
        else if (this.props.action === 'edit') {
            this.fetchUpdateModel();
        }
    }

    render() {
        let languageSelect = null;
        if (this.props.action === "edit") {
            languageSelect =
            <div >
                <label htmlFor="exampleFormControlTextarea1">Contenu du SMS:</label>
                <div className="float-end pb-2">
                    <Dropdown onSelect={this.handleChangeLanguage}>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <img className="user-img" src={utils.GetFlagById(this.state.id_language)} alt={'flag_'+this.state.id_language} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {this.state.languages.map(item =>
                                <Dropdown.Item key={item.languageId} eventKey={item.languageId}
                                    active={item.languageId === this.state.id_language ? "active" : ""}>
                                    <img className="user-img" src={utils.GetFlagById(item.languageId)} alt={'flag_id_'+item.languageId } />
                                    {" " + item.label}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        }
        let previewContent = '';
        this.state.preview_content === '' ? previewContent = 'Vous pouvez prévisualiser le modèle de SMS que vous créez ici. Utilisez les champs automatiques qui permettent d\'afficher les champs personnalisés de chaque client.'
            : previewContent = this.state.preview_content;
        return (
            <div className="row pb-3">
                <div className="col-3">
                    <div className="card">
                        <div className="card-header">
                            <b>Champs automatiques</b>
                        </div>
                        <div className="card-body btns-champs-auto">
                            {this.state.arrBtns.map((el, index) =>
                                <Button variant="outline-primary" key={index} onClick={() => this.handleChangeAutoField(el.field)}>
                                    {el.name }
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-header">
                            <b>Prévisualisation</b>
                            <button className="float-end button-a-style" onClick={this.fetchPreviewExampleStr}>
                                <img className="user-img" alt="refresh" src={require("../../Ressources/images/refresh.png")} />
                            </button>
                        </div>
                        <div className="card-body bg-light">
                            <div className="bubble-container">
                                <div className="bubble mine">
                                    {previewContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-9">
                    <div className="card">
                        <div className="card-header">
                            <b>Modèle de SMS</b>
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label htmlFor="inputSelect1" className="col-sm-4 col-form-label">Langue par défaut</label>
                                    <div className="col-sm-8">
                                        <select className="form-select" value={this.state.id_default_language}
                                            onChange={this.handleChangeDefaultLanguage} >
                                            {this.state.languages.map(item =>
                                                <option key={item.languageId} value={item.languageId}>{item.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row mt-3">
                                    <label htmlFor="inputSelect2" className="col-sm-4 col-form-label">Catégorie</label>
                                    <div className="col-sm-8">
                                        <select className="form-select" value={this.state.id_categorie}
                                            onChange={this.handleChangeCategorie} >
                                            {this.state.categories.map(item =>
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row mt-3">
                                    <label htmlFor="inputName" className="col-sm-4 col-form-label">Nom du modèle</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="inputName" placeholder="Alerte Météo, Alerte Volcan, Fermeture route, ..."
                                            value={this.state.model_name} onChange={this.handleChangeName }  />
                                    </div>
                                </div>
                                <div className="form-group row mt-3">
                                    <label htmlFor="inputURL" className="col-sm-4 col-form-label">URL complète</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="inputURL" placeholder="https://www.google.fr/"
                                            value={this.state.url} onChange={this.handleChangeUrl} />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    {languageSelect}
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="9"
                                        value={this.state.content} onChange={this.handleChangeContent}>
                                    </textarea>
                                    {this.state.nbCharLeft} avant d'ajouter une nouvelle unité de SMS <br/>
                                    Total: {this.state.nbUnite} unité(s) et {this.state.nbChar} caractère(s) <br />
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"
                                            onChange={this.handleChangeSpecialChars} checked={this.state.specialChars} />
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            J'accepte la présence de caractères spéciaux pouvant entraîner une surcharge tarifaire. &nbsp;
                                        </label>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 100, hide: 2000 }}
                                            overlay={this.renderTooltip} >
                                            <u className="ml-3 more-info">Plus d'informations</u>
                                        </OverlayTrigger>
                                    </div>
                                    <button type="submit" className="btn btn-primary float-end" >Valider</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

