import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import * as utils from '../Service/utils';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export class Home extends Component {
    static displayName = "Home";

    constructor(props) {
        super(props);
        this.state = {
            bookingNumber: '',
            nbSmsMonth: 0,
            organizationName: '',
            selectedOrg: null,
            organizations: [],
            userAuth: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeBooking = this.handleChangeBooking.bind(this);
        this.handleChangeSelectedOrg = this.handleChangeSelectedOrg.bind(this);
    }

    async componentDidMount() {
        const date = new Date();
        const month = date.getMonth() + 1; 
        const listSmsMonth = await utils.GetStatisticsMonth(month);
        this.setState({
            nbSmsMonth: listSmsMonth.length,
            organizationName: await utils.GetOrganizationName(),
            organizations: await utils.GetOrganizations(),
            userAuth: await utils.GetUserAuthorization()
        })
    }

    async handleChangeSelectedOrg(e, selectedOption) {
        await utils.SetOrganization(selectedOption.id);
        this.setState({ organizationName: await utils.GetOrganizationName() });
        window.location.reload()
    }

    handleChangeBooking(e) {
        this.setState({ bookingNumber: e.target.value });
    }

    handleSubmit(e) {
        sessionStorage.setItem("bookingNumber", this.state.bookingNumber);
        window.location.replace('/bookings');
    }
    displaySelectOrg() {
        return (
            <div className="card mt-3">
                <div className="card-header">
                    Organisation
                </div>
                <div className="card-body ">
                    <Autocomplete
                        id="grouped-demo"
                        options={this.state.organizations}
                        getOptionLabel={(option) => (option.id + ' ' + option.name)}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Sélectionnez une organisation" />}
                        onChange={this.handleChangeSelectedOrg}
                        size="small"
                    />
                </div>
            </div>
        )
    }

    render() {
        let displaySelectOrg = null;
        if (this.state.userAuth === 'SuperAdmin') {
            displaySelectOrg = this.displaySelectOrg();
        }
        let displayOrg = <p>Pas lié a une organisation</p>
        if (this.state.organizationName !== '') {
            displayOrg = <p>Organisation: {this.state.organizationName}</p>
        }
        return (
            <div>
                <h2>Citybreak SMS - Accueil</h2>
                <h5>Bienvenue {localStorage.getItem("username")}!</h5>
                {displayOrg }
                <div className="row">
                    <div className="col-lg-8 pb-3" >
                        <div className="card">
                            <div className="card-header">
                                Module SMS
                            </div>
                            <div className="card-body ">
                                <p className="card-text">
                                    Ce module permet l'envoi de messages d'alerte aux clients sur leur telephone mobile.
                                </p>
                                <div className="row">
                                    <Button href="/Bookings" className="homeBtns col-md-4 offset-md-1">
                                        Rechercher réservation
                                    </Button>
                                    <Button href="/Model" className="homeBtns col-md-4 offset-md-2">
                                        Gérer modèles
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button href="/User" className="homeBtns col-md-4 offset-md-1 mt-md-2">
                                        Gérer utilisateurs
                                    </Button>
                                    <Button href="/stat" className="homeBtns col-md-4 offset-md-2 mt-md-2">
                                        Statistiques
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button href="/schedule" className="homeBtns col-md-4 offset-md-1 mt-md-2">
                                        Planificateur d'envoi de SMS
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 pb-5" >
                        <div className="card">
                            <div className="card-header">
                                Recherche réservation
                            </div>
                            <div className="card-body ">
                                <form className="input-group mb-3" onSubmit={ this.handleSubmit }>
                                    <input type="text" className="form-control" placeholder="Numéro de réservation"
                                        aria-label="booking number" aria-describedby="basic-addon2"
                                        value={this.state.bookingNumber} onChange={this.handleChangeBooking} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary btn-home-booking" type="submit">Rechercher</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <div className="card-header">
                                Statistiques
                            </div>
                            <div className="card-body ">
                                SMS envoyés ce mois-ci: <b>{this.state.nbSmsMonth }</b>
                            </div>
                        </div>
                        {displaySelectOrg}
                    </div>
                </div>
            </div>
        );
    }
}
