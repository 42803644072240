import Spinner from 'react-bootstrap/Spinner';

function BasicLoading() {
    return (
        <div className="loading-screen d-flex align-items-center justify-content-center">
            <Spinner animation="border" role="status" style={{ width: "6rem", height: "6rem" }}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default BasicLoading;