import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

export class Stat extends Component {
    static displayName = "Stats";
    
    render() {
        return (
            <div>
                <h2>Statistiques</h2>
                <div className="btn-group user-main d-grid gap-2 d-md-block" role="group" aria-label="Basic example">
                    <Button href="/stat" className="btns-user">Statistiques</Button>
                    <Button href="/stat/search-booking" className="btns-user">Recherche de réservations</Button>
                </div>
                <Outlet />
            </div>
        );
    }
}

