import React, { Component } from 'react';
import * as utils from '../../Service/utils';

export class UserAdd extends Component {
    static displayName = "UserAdd";

    constructor(props) {
        super(props);
        this.state = {
            CB_username: '',
            id_language: 0,
            id_organization: 0,
            isAdmin: false,
            isSuperAdmin: false,
            remarque: '',
            organizations: [],
            languages: [],

            searchDisabled: true,
            isAdminDisabled: false,
            organizationsDisabled: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
        this.handleChangeOrganization = this.handleChangeOrganization.bind(this);
        this.handleChangeCBUsername = this.handleChangeCBUsername.bind(this);
        this.fetchDataOnLoadAPI = this.fetchDataOnLoadAPI.bind(this);
        this.fetchPostUser = this.fetchPostUser.bind(this);
        this.handleChangeIsAdmin = this.handleChangeIsAdmin.bind(this);
        this.handleChangeIsSuperAdmin = this.handleChangeIsSuperAdmin.bind(this);
        this.handleChangeRemarque = this.handleChangeRemarque.bind(this);
        this.onRender = this.onRender.bind(this);
    }
    componentDidMount() {
        this.fetchDataOnLoadAPI();
    }
    async fetchDataOnLoadAPI() {
        let orgs = await utils.GetOrganizations();
        let languages = await utils.GetLanguages();
        this.setState({
            organizations: orgs,
            id_organization: orgs[0].id,
            languages: languages,
            id_language: languages[0].languageId
        })
    }
    handleChangeLanguage(e) {
        this.setState({ id_language: e.target.value });
    }
    handleChangeOrganization(e) {
        this.setState({ id_organization: e.target.value });
    }
    handleChangeCBUsername(e) {
        this.setState({ CB_username: e.target.value });
    }
    handleChangeIsAdmin() {
        this.setState({ isAdmin: !this.state.isAdmin });
    }
    handleChangeIsSuperAdmin() {
        this.setState({
            isSuperAdmin: !this.state.isSuperAdmin,
            isAdminDisabled: !this.state.isAdminDisabled,
            organizationsDisabled: !this.state.organizationsDisabled,
            isAdmin: true,
            id_organization: 0
        });
    }
    handleChangeRemarque(e) {
        this.setState({ remarque: e.target.value });
    }
    async fetchPostUser() {
        let adminType = 0
        if (this.state.isAdmin && this.state.isSuperAdmin) {
            adminType = 2
        }
        else if (this.state.isAdmin) {
            adminType = 1
        }
        let formData = new FormData();
        formData.append('CbUsername', this.state.CB_username);
        formData.append('OrganizationId', this.state.id_organization);
        formData.append('LanguageId', this.state.id_language);
        formData.append('AdminType', adminType);
        formData.append('IsActive', true);
        formData.append('Remarque', this.state.remarque);

        if (await utils.PostUser(formData)) {
            window.location.replace('/user');
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.fetchPostUser();
    }

    onRender() {
        if (this.state.CB_username !== '' && this.state.id_language != 0) {
            this.state.searchDisabled = false;
        }
        else { this.state.searchDisabled = true; }
    }

    render() {
        this.onRender();

        return (
            <div className="card">
                <div className="card-body row">
                    <form onSubmit={this.handleSubmit}>
                        <div className="row mb-3">
                            <label htmlFor="inputUsername" className="col-sm-4 col-form-label">Nom d'utilisateur:</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="inputUsername"
                                    placeholder="Identifiant Citybreak" value={this.state.CB_username}
                                    onChange={this.handleChangeCBUsername} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-sm-4 col-form-label" >Langue: </label>
                            <div className="col-sm-4">
                                <select className="form-select" value={this.state.id_language}
                                    onChange={this.handleChangeLanguage} >
                                    {this.state.languages.map(item =>
                                        <option key={item.languageId} value={item.languageId} > {item.label} </option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-sm-4 col-form-label" >Organisation: </label>
                            <div className="col-sm-4">
                                <select className="form-select" value={this.state.id_organization}
                                    disabled={(this.state.organizationsDisabled) ? "disabled" : ""}
                                    onChange={this.handleChangeOrganization} >
                                    {this.state.organizations.map(item =>
                                        <option key={item.id} value={item.id}>{item.nomOrga} / id: {item.idOrga}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="checkBox1" className="col-sm-4 col-form-label">Administrateur:</label>
                            <div className="col-sm-4">
                                <input type="checkbox" className="form-check-input" id="checkBox1"
                                    onChange={this.handleChangeIsAdmin} checked={this.state.isAdmin}
                                    disabled={(this.state.isAdminDisabled) ? "disabled" : ""} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="checkBox2" className="col-sm-4 col-form-label">Administrateur Global:</label>
                            <div className="col-sm-4">
                                <input type="checkbox" className="form-check-input" id="checkBox2"
                                    onChange={this.handleChangeIsSuperAdmin} checked={this.state.isSuperAdmin} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="inputRemarque" className="col-sm-4 col-form-label">Remarque:</label>
                            <div className="col-sm-4">
                                <textarea type="text" className="form-control" id="inputRemarque" rows="3"
                                    value={this.state.remarque} onChange={this.handleChangeRemarque} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <button type="submit" className="btn btn-primary col-md-4 offset-md-4 btns-user rounded-corners"
                                disabled={(this.state.searchDisabled) ? "disabled" : ""}>Envoyer</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
