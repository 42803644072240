import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import Button from 'react-bootstrap/Button';

export class ModelCatAdd extends Component {
    static displayName = "ModelAdd";

    constructor(props) {
        super(props);
        this.state = {
            cat_name: ''
        };
        this.handleChangeName = this.handleChangeName.bind(this);
        this.fetchPostCategory = this.fetchPostCategory.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeName(e) {
        this.setState({ cat_name: e.target.value })
    }

    async fetchPostCategory() {
        utils.PostModelCat(this.state.cat_name)
    }

    handleSubmit(e) {
        e.preventDefault();
        this.fetchPostCategory();
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        Ajouter une catégorie
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group row mt-3">
                                <label htmlFor="inputName" className="col-sm-4 col-form-label">Nom de la catégorie</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" id="inputName" placeholder="Alerte, Info Général ..."
                                        value={this.state.cat_name} onChange={this.handleChangeName} />
                                </div>
                            </div>
                            <Button type="submit" >Valider</Button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

