import React, { Component } from 'react';
import { Grid, TextField } from '@mui/material';

export class DurationPicker extends Component {

    render() {
        return (
            <div className="mt-1">
                Vous pouvez choisir une durée en année, mois, jours et heures.
                <div className="mt-3 text-center" >
                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                            <TextField
                                label="Mois"
                                type="number"
                                value={this.props.duration.months}
                                onChange={(e) => this.props.handleDurationChange('months', parseInt(e.target.value))}
                                size="small"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 24
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Jours"
                                type="number"
                                value={this.props.duration.days}
                                onChange={(e) => this.props.handleDurationChange('days', parseInt(e.target.value))}
                                size="small"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 50
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Heures"
                                type="number"
                                value={this.props.duration.hours}
                                onChange={(e) => this.props.handleDurationChange('hours', parseInt(e.target.value))}
                                size="small"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 24
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
                {this.props.durationString}
                <div className="mt-2">
                    <p>Choisissez à partir de quel moment voulez vous que la durée soit décompter:</p>
                    <select className="form-select" value={this.props.selectedFromWhenType}
                        onChange={this.props.handleSelectedFromWhenType} >
                        <option value={0} >à partir de maintenant </option>
                        <option value={1} disabled >jour d'arrivée </option>
                        <option value={2} disabled >jour du départ </option>
                    </select>
                </div>
            </div>
        )
    }
}