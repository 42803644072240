import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import Image from 'react-bootstrap/Image';

export class UserLogo extends Component {
    static displayName = "UserLogo";
    constructor(props) {
        super(props);
        this.state = {
            organizations: [],
            id_organization: 0,
            organizationSelectDisabled: true,
            file: null,
            searchDisabled: true,
            fileName: 'unknown.png',
            srcImg: process.env.PUBLIC_URL + '/assets/logos/',
            logoLoaded: false
        };
        this.handleChangeOrganization = this.handleChangeOrganization.bind(this);
        this.fetchDataOnLoadAPI = this.fetchDataOnLoadAPI.bind(this);
        this.fetchCheckLogoExists = this.fetchCheckLogoExists.bind(this);
        this.saveFile = this.saveFile.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.onRender = this.onRender.bind(this);
    }
    componentDidMount() {
        this.fetchDataOnLoadAPI();
    }
    componentDidUpdate() {
        if (this.state.organizations.length > 0 && !this.state.logoLoaded) {
            this.fetchCheckLogoExists();
            this.state.logoLoaded = true;
        }
    }
    handleChangeOrganization(e) {
        this.state.id_organization = e.target.value;
        this.fetchCheckLogoExists(); // can't fetch with a setState so I did this.state.id_organization... instead
    }

    //save file data in this.state.file after selection with file explorer
    saveFile(e) {
        this.setState({ file: e.target.files[0] });
    }
    async handleUpload(e) {
        e.preventDefault();
        if (this.state.file != null) {
            let formData = new FormData();
            formData.append('organizationId', this.state.id_organization);
            formData.append('file', this.state.file);

            //Uppload logo file in /Ressources/image/logo_orga/ and add file data in DB
            this.setState({
                responseString: await utils.UploadImage(formData)
            });
        }
    }

    async fetchDataOnLoadAPI() {
        let orgs = await utils.GetOrganizations();
        if (orgs.length > 1) {
            this.setState({ organizationSelectDisabled:false })
        }
        this.setState({
            organizations: orgs,
            id_organization: orgs[0].id
        })
    }

    //Check if logo exists in DB and in Ressources folder
    async fetchCheckLogoExists() {
        this.setState({
            fileName: await utils.CheckLogoExists(this.state.id_organization)
        })
    }

    onRender() {
        if (this.state.file !== null) {
            this.state.searchDisabled = false;
        }
        else { this.state.searchDisabled = true; }
    }
    render() {
        this.onRender();
        return (
            <div className="card">
                <div className="card-header">
                    Ajouter un logo à une organisation.
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col col-8">
                            <form onSubmit={this.handleUpload}>
                                <div className="row mb-3">
                                    <label className="col-sm-4 col-form-label" >Organisation: </label>
                                    <div className="col-sm-8">
                                        <select className="form-select" value={this.state.id_organization}
                                            disabled={(this.state.organizationSelectDisabled) ? "disabled" : ""}
                                            onChange={this.handleChangeOrganization} >
                                            {this.state.organizations.map(item =>
                                                <option key={item.id} value={item.id}>{item.nomOrga} / id: {item.idOrga}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 form-label" htmlFor="customFile">Fichier Logo:</label>
                                    <div className="col-sm-8">
                                        <input type="file" className="form-control" id="customFile" onChange={this.saveFile } />
                                    </div>
                                </div>
                                <button className="btn btn-primary btns-user rounded-corners" type="submit"
                                    disabled={(this.state.searchDisabled) ? "disabled" : ""} >
                                    Valider
                                </button>
                            </form>
                        </div>
                        <div className="col col-4">
                            <Image className="bg-light img-thumbnail rounded img-fluid" src={this.state.srcImg + this.state.fileName}></Image>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    //<Iframe src={process.env.PUBLIC_URL + "/assets/blankIframe.html" } height="0" width="0"/>
}
