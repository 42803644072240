import React, { Component } from 'react';
import { BookingSearch } from "./BookingSearch";

export class Booking extends Component {
    static displayName = "Réservations";

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <div>
                <h2>Recherche de réservations</h2>
                <BookingSearch action="search" />
            </div>
        );
    }
}