import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NormalLogin } from './NormalLogin';
import { FirstLogin } from './FirstLogin';

export class Login extends Component {
    static displayName = "Login";
    constructor(props) {
        super(props);
        this.state = {
            isFirstLogin: localStorage.getItem('isFirstLogin'),
        };
    }

    render() {
        return (
            <Container className="login-container">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-xl-8">
                        <div className="card rounded-3 text-black">
                            <div className="row g-0">
                                <div className="col-lg-5 vg-pattern">
                                    <img className="login-logo" src={require("../../Ressources/images/logo.png")} alt="logo" />
                                </div>
                                <div className="col-lg-7 d-flex">
                                    <div className="card-body p-md-5 mx-md-4 mt-2">
                                        {this.state.isFirstLogin ? <FirstLogin /> : <NormalLogin />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}