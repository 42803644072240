import { useState, useEffect } from 'react';
import * as utils from '../../Service/utils';

export default function useToken() {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const checkToken = async () => {
            const storedToken = utils.GetCookie('session-id');
            if (storedToken) {
                const isTokenOk = await utils.VerifyToken(storedToken);
                if (isTokenOk === true) {
                    setToken(storedToken);
                } else {
                    setToken(null);
                }
            } else {
                setToken(null);
            }

            setLoading(false); // Set loading to false after token verification
        };
        checkToken();
    }, []);

    return {
        token,
        loading, 
        setToken: (newToken) => {
            setToken(newToken);
            utils.SetCookie('session-id', newToken);
        },
    };
}