import React, { Component } from 'react';
import * as utils from '../../Service/utils';


export class NormalLogin extends Component {
    static displayName = "Login";

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeUsername(e) {
        this.setState({ username: e.target.value });
    }
    handleChangePassword(e) {
        this.setState({ password: e.target.value });
    }

    async handleSubmit(e) {
        e.preventDefault();
        let formData = new FormData();
        formData.append('Username', this.state.username);
        formData.append('Password', this.state.password);
        const loginResponse = await utils.loginUser(formData);
        if (loginResponse !== false) {
            localStorage.setItem("username", this.state.username);
            if (loginResponse.action === 0) {
                window.location.reload(false);
            }
            else {
                localStorage.setItem("isFirstLogin", true);
                window.location.reload(false);
            }
        }
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <h5>Entrez vos identifiants</h5>
                <div className="form-outline mb-2">
                    <input type="text" className="form-control" placeholder="identifiant"
                        value={this.state.username} onChange={this.handleChangeUsername} />
                </div>
                <div className="form-outline mb-2">
                    <input type="password" className="form-control" placeholder="mot de passe"
                        value={this.state.password} onChange={this.handleChangePassword} />
                </div>
                <div className="mt-4">
                    <button className="btn btn-primary rounded-corners homeBtns"
                        type="submit">Se connecter</button>
                </div>
            </form>
        )
    }
}