import React, { Component } from 'react';

export class BookingTableSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="table-responsive">
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Numéro Résa</th>
                            <th>Nom du client</th>
                            <th>Date de résa</th>
                            <th>Date d'arrivée</th>
                            <th>Réseau Org.</th>
                            <th>Numéro de Tel</th>
                            <th>Pays</th>
                            <th>Type Produit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.bookings.map(item =>
                            <tr key={item.id}>
                                <td>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                            value={item.userId} id="flexCheckDefault"
                                            onChange={this.props.handleSelectedBookings} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault"></label>
                                    </div>
                                </td>
                                <td>{item.bookingNumber}</td>
                                <td>{item.clientName}</td>
                                <td>{item.bookingDate}</td>
                                <td>{item.arrivalDate}</td>
                                <td>{item.nomOrga}</td>
                                <td>{item.phoneCell}</td>
                                <td>{item.country}</td>
                                <td>{item.productGroup}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }

}
