import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from "react-bootstrap/Modal";
import DisplayList from '../Structure/DisplayList';
import { BookingTableSelect } from './BookingTableSelect';
import dayjs from 'dayjs';


export class BookingSearch extends Component {
    static displayName = "Réservations";
    constructor(props) {
        super(props);
        this.state = {
            bookingNo: '',
            clientName: '',
            fromDate: '',
            toDate: '',
            searchType: 'bookingDate',
            channel: 0,
            suppliers: [],
            agentNo: 0,
            invoiceNo: 0,
            CCId: 0,
            chkNew: true,
            chkChanged: true,
            chkCancelled: false,
            chkAnonymized: false,

            dateDisabled: true,
            searchDisabled: true,
            showModal: false,
            bookings: [],
            loading: true
        };
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.handleCheckBoxDate = this.handleCheckBoxDate.bind(this);
        this.handleChangeBookNum = this.handleChangeBookNum.bind(this);
        this.handleChangeClientName = this.handleChangeClientName.bind(this);
        this.handleChangeSelectType = this.handleChangeSelectType.bind(this);
        this.handleChangeSelectChannel = this.handleChangeSelectChannel.bind(this);
        this.handleChangeAgentNo = this.handleChangeAgentNo.bind(this);
        this.handleChangeInvoiceNo = this.handleChangeInvoiceNo.bind(this);
        this.handleChangeCCId = this.handleChangeCCId.bind(this);
        this.handleChangeChkNew = this.handleChangeChkNew.bind(this);
        this.handleChangeChkChanged = this.handleChangeChkChanged.bind(this);
        this.handleChangeChkCancelled = this.handleChangeChkCancelled.bind(this);
        this.handleChangeChkAnonymized = this.handleChangeChkAnonymized.bind(this);

        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchBookingsAPI = this.fetchBookingsAPI.bind(this);
        this.onRender = this.onRender.bind(this);
    }
    componentDidMount() {
        if (sessionStorage.getItem('bookingNumber')) {
            this.state.bookingNo = sessionStorage.getItem('bookingNumber');

            this.handleSearch();
            this.fetchBookingsAPI();
            sessionStorage.removeItem('bookingNumber');
        }
    }
    handleFromDateChange(date) {
        this.setState({ fromDate: date })
    }
    handleToDateChange(date) {
        this.setState({ toDate: date })
    }
    handleCheckBoxDate() {
        this.setState({
            dateDisabled: !this.state.dateDisabled,
        })
        if (!this.state.dateDisabled) {
            this.setState({
                fromDate: null,
                toDate: null
            })
        }
        else {
            this.setState({
                fromDate: Date.now(),
                toDate: Date.now()
            })
        }
    }

    handleModalOpen() {
        this.setState({
            showModal: true
        })
    }
    handleModalClose() {
        this.setState({
            showModal: false
        })
    }

    handleSubmit(e) {
        this.handleSearch();
        this.fetchBookingsAPI();
        e.preventDefault();
    }
    handleChangeBookNum(e) {
        this.setState({ bookingNo: e.target.value })
    }
    handleChangeClientName(e) {
        this.setState({ clientName: e.target.value })
    }
    handleChangeSelectType(e) {
        this.setState({ searchType: e.target.value })
    }
    handleChangeSelectChannel(e) {
        this.setState({ channel: e.target.value })
    }
    handleChangeAgentNo(e) {
        this.setState({ agentNo: e.target.value })
    }
    handleChangeInvoiceNo(e) {
        this.setState({ invoiceNo: e.target.value })
    }
    handleChangeCCId(e) {
        this.setState({ CCId: e.target.value })
    }
    handleChangeChkNew(e) {
        this.setState({ chkNew: e.target.checked })
    }
    handleChangeChkChanged(e) {
        this.setState({ chkChanged: e.target.checked })
    }
    handleChangeChkCancelled(e) {
        this.setState({ chkCancelled: e.target.checked })
    }
    handleChangeChkAnonymized(e) {
        this.setState({ chkAnonymized: e.target.checked })
    }
    handleSearch() {
        this.setState({ loading: true })
        this.forceUpdate()
    }
    async fetchBookingsAPI() {
        let formData = new FormData();
        let FromDateStr = '';
        let ToDateStr = '';

        if (this.state.fromDate) {
            FromDateStr = new Date(this.state.fromDate).toLocaleDateString()
        }
        if (this.state.toDate) {
            ToDateStr = new Date(this.state.toDate).toLocaleDateString()
        }

        formData.append('BookingNumber', this.state.bookingNo);
        formData.append('ClientName', this.state.clientName);
        formData.append('FromDate', FromDateStr);
        formData.append('ToDate', ToDateStr);
        formData.append('SearchType', this.state.searchType);
        formData.append('Channel', this.state.channel);
        
        const bookingsList = await utils.GetBookings(formData);

        if (bookingsList !== undefined) {
            this.setState({
                bookings: bookingsList,
                loading: false
            })
        }
    }

    onRender() {
        if (this.state.dateDisabled) {
            if (this.state.bookingNo) {
                this.state.searchDisabled = false;
            }
            else {
                this.state.searchDisabled = true;
            }
        }
        else if (this.state.fromDate > this.state.toDate) {
            this.state.searchDisabled = true;
        }
        else {
            this.state.searchDisabled = false;
        }
    }

    render() {
        let content = null;

        if (this.state.loading) {
            content = <p><em>Loading...</em></p>
        }
        else if (this.props.action === 'select') {
            content = 
                <BookingTableSelect
                bookings={this.state.bookings}
                handleSelectedBookings={this.props.handleSelectedBookings} />
        }
        else{
            content = <DisplayList
                list={this.state.bookings}
                recordsType={"booking"}
                languages={this.state.languages}
                pagination={true} />
        }

        this.onRender();

        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="card mb-2">
                        <div className="card-body row">
                            <div className="form-group col-4 col-xl-2">
                                <label htmlFor="numBook">Numéro de réservation</label>
                                <input type="text" className="form-control" id="numBook"
                                    value={this.state.bookingNo}
                                    onChange={this.handleChangeBookNum} />
                            </div>
                            <div className="form-group col-4 col-xl-2">
                                <label htmlFor="clientName">nom du client</label>
                                <input type="text" className="form-control"
                                    id="clientName" value={this.state.clientName}
                                    onChange={this.handleChangeClientName} />
                            </div>
                            <div className="form-floating col-auto big-checkbox-wrapping">
                                <input className="form-check-input big-checkbox" type="checkbox"
                                    value="" aria-label="..." onChange={this.handleCheckBoxDate} />
                            </div>
                            <div className="form-group col-4 col-xl-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="A partir de"
                                        size="small"
                                        value={this.state.fromDate ? dayjs(this.state.fromDate) : null}
                                        onChange={this.handleFromDateChange}
                                        disabled={this.state.dateDisabled}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="form-group col-4 col-xl-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Jusqu'a"
                                        size="small"
                                        value={this.state.toDate ? dayjs(this.state.toDate) : null}
                                        onChange={this.handleToDateChange}
                                        disabled={this.state.dateDisabled}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="form-group col-5 col-xl-2">
                                <label htmlFor="selectSearchType">Type de recherche </label>
                                <select className="form-select" value={this.state.searchType}
                                    onChange={this.handleChangeSelectType}
                                    disabled={this.state.dateDisabled}>
                                    <option value="bookingDate">Date de réservation</option>
                                    <option value="arrivalDate">Date d'arrivée</option>
                                    <option value="departureDate">Date de départ</option>
                                </select>
                            </div>
                            <div className="form-group col-5 col-xl-auto">
                                <label htmlFor="selectReseaux">Réseau </label>
                                <select className="form-select" value={this.state.channel}
                                    onChange={this.handleChangeSelectChannel}>
                                    <option value="all">Tous</option>
                                </select>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <button className="btn btn-primary rounded-corners" type="submit"
                                disabled={(this.state.searchDisabled) ? "disabled" : ""} >Recherche</button>
                            <button type="button" className="btn btn-moreTypes rounded-corners" onClick={this.handleModalOpen}>
                                Plus de critères de recherche
                            </button>
                        </div>
                    </div>
                    <Modal show={this.state.showModal} onHide={this.handleModalClose} >
                        <Modal.Header>
                            <Modal.Title>Plus de critères de recherche</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label htmlFor="idSuppliers">Fournisseurs: </label>
                                <select className="form-select" value={this.state.channel}
                                    onChange={this.handleChangeSelectChannel}>
                                    <option value="all">Tous</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="idAgent">Numéro d'agent</label>
                                <input type="text" className="form-control" id="idAgent" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="idBill">Numéro de facture</label>
                                <input type="text" className="form-control" id="idBill" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="idTran">ID transaction Carte de crédit</label>
                                <input type="text" className="form-control" id="idTran" />
                            </div>
                            <div className="form-floating col-auto ">
                                <input className="form-check-input big-checkbox" type="checkbox"
                                    id="checkBox" value=""
                                    onChange={this.handleChangeChkNew}
                                    checked={this.state.chkNew}
                                />
                                <label className="form-check-label">
                                    Nouveau
                                </label>
                            </div>
                            <div className="form-floating col-auto ">
                                <input className="form-check-input big-checkbox" type="checkbox"
                                    id="checkBox" value=""
                                    onChange={this.handleChangeChkChanged}
                                    checked={this.state.chkChanged}
                                />
                                <label className="form-check-label">
                                    Modifié
                                </label>
                            </div>
                            <div className="form-floating col-auto ">
                                <input className="form-check-input big-checkbox" type="checkbox"
                                    id="checkBox" value=""
                                    onChange={this.handleChangeChkCancelled}
                                    checked={this.state.chkCancelled}
                                />
                                <label className="form-check-label">
                                    Annulé
                                </label>
                            </div>
                            <div className="form-floating col-auto ">
                                <input className="form-check-input big-checkbox" type="checkbox"
                                    id="checkBox" value=""
                                    onChange={this.handleChangeChkAnonymized}
                                    checked={this.state.chkAnonymized}
                                />
                                <label className="form-check-label">
                                    Only anonymized
                                </label>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-success rounded-corners" onClick={this.handleModalClose}>Fermer</button>
                        </Modal.Footer>
                    </Modal>
                </form>
                {content}
            </React.Fragment>
        );
    }
}