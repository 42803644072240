import React, { Component } from 'react';
import {
    Collapse, Navbar, NavbarBrand,
    NavbarToggler, NavItem, NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import * as utils from '../../Service/utils';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            username: '',
            colorClass: '',
            displayUsername: null
        };
    }

    async componentDidMount() {
        let userAuth = await utils.GetUserAuthorization()
        let username = localStorage.getItem("username") ?? 'no username';
        switch (userAuth) {
            case 'SuperAdmin':
                this.setState({ colorClass: "text-danger" });
                break;
            case 'Admin':
                this.setState({ colorClass: "text-info" });
                break;
            case 'Normal':
                this.setState({ colorClass: "text-body" });
                break;
            default:
                this.setState({ colorClass: "" });
                break;
        }
        this.setState({ username: username })
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    
    async Logout() {
        await utils.SignOut();
        sessionStorage.clear();
        window.location.reload(false);
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-lg navbar-toggleable-lg ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/">
                        <img alt="CitybreakSMS" src="https://gateway.citybreak.fr/sms/resources/images/logo.png" />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/">Accueil</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/schedule">Planificateur SMS</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/bookings">Recherche de Réservations</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/model">Modèles</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/user">Utilisateurs</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/stat">Statistiques</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink disabled className={this.state.colorClass }>
                                    <b>{this.state.username}</b>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/" onClick={this.Logout }>
                                    <img className="navbar-img" alt="logout" src={require("../../Ressources/images/logout.png")} />
                                </NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}


//Dropdown nav item
//<UncontrolledDropdown nav inNavbar>
//    <DropdownToggle nav caret className="text-dark">
//        Modèles
//    </DropdownToggle>
//    <DropdownMenu end >
//        <DropdownItem tag={Link} className="text-dark" to="/model/new">Ajout</DropdownItem>
//        <DropdownItem tag={Link} className="text-dark" to="/model">Liste</DropdownItem>
//        <DropdownItem tag={Link} className="text-dark" to="/model/archive">Liste archive</DropdownItem>
//        <DropdownItem tag={Link} className="text-dark" to="/model/categories">Liste Catégories</DropdownItem>
//        <DropdownItem tag={Link} className="text-dark" to="/model/new-category">Ajout catégorie</DropdownItem>
//    </DropdownMenu>
//</UncontrolledDropdown>