import React, { Component } from 'react';

export class StatBookSearch extends Component {
    static displayName = "StatBookSearch";

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isOpen: false,
            statistics: []
        };
    }


    render() {
        return (
            <div>
                not implemented yet...
            </div>
        );
    }
}

