import React, { Component } from 'react';
import { ModelAddEdit } from './ModelForm';

export class ModelEdit extends Component {
    static displayName = "ModelEdit";
    
    constructor(props) {
        super(props);
        this.state = {
            modelId: 0,
            languageId: 0
        };
        this.fetchDataOnLoadAPI = this.fetchDataOnLoadAPI.bind(this);
    }

    componentDidMount() {
        this.fetchDataOnLoadAPI();
    }

    async fetchDataOnLoadAPI() {
        const queryParameters = new URLSearchParams(window.location.search);
        let modelId = queryParameters.get("model_id");
        let languId = queryParameters.get("languId");
        this.setState({
            modelId: modelId,
            languageId: languId
        })
    }

    render() {
        return (
            <ModelAddEdit action="edit" modelId={this.state.modelId} languageId={this.state.languageId} />
        );
    }
}