import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

export class StatDetails extends Component {
    static displayName = "StatDetails";

    constructor(props) {
        super(props);
        this.state = {
            smsList: this.props.list,
            filteredSmsList: this.props.list,
            bookingNumber: '',
            userId: '',
            headerDateInfo: 'undefined'
        };
        this.handleBookingNumber = this.handleBookingNumber.bind(this);
        this.handleUserID = this.handleUserID.bind(this);
    }

    handleBookingNumber(e) {
        const bookingNumber = e.target.value.toUpperCase();

        this.setState({ bookingNumber }, () => {
            const filteredSmsList = this.state.smsList.filter(
                sms => sms.smsSent.numero.includes(bookingNumber)
            );
            this.setState({ filteredSmsList });
        });
    }

    handleUserID(e) {
        const userId = e.target.value;

        this.setState({ userId }, () => {
            const filteredSmsList = this.state.smsList.filter(
                sms => sms.smsSent.userId.toString().includes(userId)
            );
            this.setState({ filteredSmsList });
        });
    }

    componentDidMount() {
        if (this.state.smsList.length > 0) {
            const year = this.state.smsList[0].year;
            const month = this.state.smsList[0].month;
            const dateWeek = new Date(year, month - 1);
            const options = { year: 'numeric', month: 'long' };
            const formattedDate = dateWeek.toLocaleDateString('fr-FR', options);
            const monthStr = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

            this.setState({
                headerDateInfo: monthStr + ' / Semaine ' + this.state.smsList[0].week
            });
        }
    }
    
    render() {
        let statDetailsContent = null;
        if (this.state.filteredSmsList.length > 0) {
            statDetailsContent = <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Contenu</th>
                        <th>Coût de crédit</th>
                        <th>Numéro</th>
                        <th>ID Utilisateur</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.filteredSmsList.map((sms) => (
                        <tr key={sms.smsSent.id}>
                            <td>{sms.smsSent.contenu}</td>
                            <td>{sms.smsSent.creditCost}</td>
                            <td>{sms.smsSent.numero}</td>
                            <td>{sms.smsSent.userId}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        }
        else {
            statDetailsContent = null;
        }
        return (
            <div className="container">
                <div className="row align-items-center">
                    <p className="col d-flex flex-column">{this.state.headerDateInfo} </p>
                    <div className="col-2 mb-4">
                        <input type="text" className="form-control" placeholder="Numéro" aria-label="Numéro"
                            value={this.state.bookingNumber} onChange={this.handleBookingNumber}
                        />
                    </div>
                    <div className="col-2 mb-4">
                        <input type="number" className="form-control" placeholder="ID Utilisateur" aria-label="ID Utilisateur"
                            value={this.state.userId} onChange={this.handleUserID}
                        />
                    </div>
                    <Link className="btn btn-secondary col-2 float-end mb-4" to="#" onClick={() => this.props.handleSelectedWeekStats(null)}>
                        Retour
                    </Link>
                </div>
                <div className="pb-5">
                    {statDetailsContent }
                </div>
            </div>
        );
    }
}