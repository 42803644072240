import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import * as modals from '../Structure/LayoutModals';
import DisplayList from '../Structure/DisplayList';

export class UserList extends Component {
    static displayName = "UserList";

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            users: [],
            showModal: false,
            selectedUser: null
        };
        this.fetchDataOnLoadAPI = this.fetchDataOnLoadAPI.bind(this);
        this.handleChangeListOrg = this.handleChangeListOrg.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentDidMount() {
        this.fetchDataOnLoadAPI();
    }
    handleModalOpen(user) {
        if (user.id !== undefined) {
            this.state.selectedUser = user;
        }
        this.setState({ showModal: !this.state.showModal })
    }
    async deleteUser() {
        const updatedUsers = [...this.state.users];
        const userToRemove = updatedUsers.findIndex(obj => obj.id === this.state.selectedUser.id);
        if (userToRemove !== -1) {
            if (await utils.DeleteUser(this.state.selectedUser.id))
            updatedUsers.splice(userToRemove, 1);
            this.setState({
                showModal: false,
                selectedUser: null,
                users: updatedUsers
            });
        }
    }
    async fetchDataOnLoadAPI() {
        this.setState({
            loading: false,
            users: await utils.GetUsers()
        });
    }
    handleChangeListOrg(e) {
        this.setState({
            idOrganizationList: e.target.value
        })
    }
    displayModal() {
        const modalTitle = <p>Voulez vous supprimer cet utilisateur ?</p>;
        const modalBody = <p>{this.state.selectedUser !== undefined ? this.state.selectedUser.username : ''}</p>
        const modalFooter =
            <div>
                <button className="btn btn-danger rounded-corners me-2" onClick={this.deleteUser}>Confirmer</button>
                <button className="btn btn-secondary rounded-corners" onClick={this.handleModal}>Annuler</button>
            </div>
        return modals.displayLayoutModal(this.state.showModal, this.handleModalClose, modalTitle, modalBody, modalFooter)
    }

    render() {
        console.log(this.state.users)
        let modal = null;
        let content = null;

        this.state.selectedUser === null ? modal = null : modal = this.displayModal()

        if (this.state.loading) {
            content = <p><em>Loading...</em></p>
        }
        else {
            content = <DisplayList
                list={this.state.users}
                recordsType={"users"}
                handleModalOpen={this.handleModalOpen}
                pagination={true}
            />
        }

        this.state.selectedUser === null ? modal = null : modal = this.displayModal()
        return (
            <div>
                {content}
                {modal}
            </div>
        );
    }
}
