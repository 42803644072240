import React from 'react'

function Pagination(props) {
    return (
        <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={() => props.handleChangeCurrentPage(props.currentPage - 1)} >
                        &#60;
                    </a>
                </li>
                {props.pageNumbers.map(page => (
                    <li key={page}
                        className={`page-item ${props.currentPage === page ? 'active' : ''} `} >

                        <a onClick={() => props.handleChangeCurrentPage(page)}
                            className='page-link'>
                            {page}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link"
                        onClick={() => props.handleChangeCurrentPage(props.currentPage + 1)}>
                        &#62;
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination