import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';

export class User extends Component {
    static displayName = "Users";
    
    render() {
        return (
            <div>
                <h2>Gérer Utilisateurs</h2>
                <div className="btn-group user-main d-grid gap-2 d-md-block" role="group" aria-label="Basic example">
                    <a href="/user/new" type="button" className="btn btn-primary btns-user">Ajouter un utilisateur</a>
                    <a href="/user" type="button" className="btn btn-primary btns-user">Liste des utilisateurs</a>
                    <a href="/user/logo" type="button" className="btn btn-primary btns-user">Ajouter un Logo</a>
                </div>
                <Outlet />
            </div>
        );
    }
}

