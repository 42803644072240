import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export class FirstLogin extends Component {
    static displayName = "FirstLogin";

    constructor(props) {
        super(props);
        this.state = {
            username: localStorage.getItem('username'),
            organizationId: 0,
            languageId: 0,
            languages: [],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChangeSelectedLanguage = this.handleChangeSelectedLanguage.bind(this);
    }

    async componentDidMount() {
        const languages = await utils.GetLanguages()
        this.setState({
            languages: languages,
            languageId: languages[0].languageId
        })
    }

    async handleSubmit(e) {
        e.preventDefault();
        let formData = new FormData();
        formData.append('Username', this.state.username);
        formData.append('OrganizationId', this.state.organizationId);
        formData.append('LanguageId', this.state.languageId);
        const res = await utils.firstLoginUser(formData);
        if (res !== false) {
            localStorage.removeItem('isFirstLogin')
            window.location.reload(false);
        }
    }

    async handleChangeSelectedLanguage(e, selectedOption) {
        this.setState({
            languageId: selectedOption.languageId
        })
    }

    handleCancel() {
        localStorage.clear()
        //TODO: remove cookie if exists to be sure
        window.location.reload(false);
    }

    renderLanguageOption(language) {
        const countryFlag = utils.GetFlagById(language.languageId)
        return (
            <p>
                <img className="user-img" src={countryFlag} alt={countryFlag} />
            </p>
        )
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <h5>Première connexion</h5>
                <div>
                    <p className="mb-0">
                        identifiant Citybreak: {this.state.username}
                    </p>
                    <p>
                        Organisation: {this.state.organizationId}
                    </p>
                </div>
                <Autocomplete
                    id="grouped-demo"
                    options={this.state.languages}
                    getOptionLabel={(option) => (option.label)}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Sélectionnez une langue" />}
                    onChange={this.handleChangeSelectedLanguage}
                    size="small"
                    disabled={this.state.languages === undefined}
                    className="mt-3"
                />

                <div className="mt-4">
                    <button className="btn btn-primary rounded-corners homeBtns" type="submit">
                        Enregistrer et se connecter</button>
                    <button className="btn btn-primary rounded-corners homeBtns ms-3" onClick={this.handleCancel} >
                        Retour</button>
                </div>
            </form>
        )
    }
}