import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import * as modals from "../Structure/LayoutModals";
import DisplayList from '../Structure/DisplayList';

export class ModelArchive extends Component {
    static displayName = "ModelList";

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            selectedModel: {},
            selectedModalAction: '',
            loading: true,
            models: []
        };
        this.fetchDataOnLoadAPI = this.fetchDataOnLoadAPI.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
    }

    componentDidMount() {
        this.fetchDataOnLoadAPI();
    }

    async fetchDataOnLoadAPI() {
        this.setState({
            models: await utils.GetModels(true),
            loading: false
        })
    }

    async handleModalOpen(model, action, confirmDelete) {
        this.setState({
            showModal: true,
            selectedModel: model,
            selectedModalAction: action
        })

        switch (action) {
            case "unarchived":
                this.handleModalClose();
                let formData = new FormData();
                formData.append('Id', model.id);
                formData.append('ModelName', null);
                formData.append('LanguageDefaultId', -1);
                formData.append('OrganizationId', -1);
                formData.append('CategoryId', -1);
                formData.append('IsArchived', false);
                formData.append('LanguageId', -1);
                formData.append('MessageContent', null);

                if (await utils.UpdateModel(formData)) {
                    await this.removeItemFromList(model.id)
                }
                break;
            case "deleteModel":
                if (confirmDelete === true) {
                    if (await utils.DeleteModel(model.id)) {
                        await this.removeItemFromList(model.id)
                    }
                }
                break;
            default:
                break;
        }
    }

    handleModalClose() {
        this.setState({
            showModal: false,
            selectedModel: null
        })
    }

    async removeItemFromList(modelId) {
        const updatedModels = [...this.state.models];
        const modelToRemove = updatedModels.findIndex(obj => obj.id === modelId);
        if (modelToRemove !== -1) {
            updatedModels.splice(modelToRemove, 1);
            this.setState({
                showModal: false,
                models: updatedModels
            });
        }
    }

    displayModal() {
        const modalTitle = <p>Supprimer définitivement ce modèle</p>;
        const modalBody = <p>souhaitez-vous supprimer de manière définitive le modèle: <b>{this.state.selectedModel.nomModele}</b></p>
        const modalFooter = 
            <div>
                <button className="btn btn-danger rounded-corners me-2" onClick={() => this.handleModalOpen(this.state.selectedModel, "deleteModel", true)}>Supprimer</button>
                <button className="btn btn-primary rounded-corners" onClick={this.handleModalClose}>Fermer</button>
            </div>
        return modals.displayLayoutModal(this.state.showModal, this.handleModalClose, modalTitle, modalBody, modalFooter)
    }

    render() {
        let modal = null;
        let content = null;
        this.state.selectedModel === null ? modal = null : modal = this.displayModal()

        if (this.state.loading) {
            content = <p><em>Loading...</em></p>
        }
        else {
            content = <DisplayList
                list={this.state.models}
                recordsType={"archive"}
                handleModalOpen={this.handleModalOpen}
                languages={this.state.languages}
                pagination={true}
            />
        }

        return (
            <div>
                {content}
                {modal}
            </div>
        );
    }
}