import React, { lazy, Suspense } from 'react';

const RecordsOfModels = lazy(() => import('../components/Structure/Records/RecordsOfModels'));
const RecordsOfModelsArchived = lazy(() => import('../components/Structure/Records/RecordsOfModelsArchived'));
const RecordsOfUsers = lazy(() => import('../components/Structure/Records/RecordsOfUsers'));
const RecordsOfCategories = lazy(() => import('../components/Structure/Records/RecordsOfCategories'));
const RecordsOfBookings = lazy(() => import('../components/Structure/Records/RecordsOfBookings'));
const RecordsOfStats = lazy(() => import('../components/Structure/Records/RecordsOfStats'));
const RecordsOfClientBookings = lazy(() => import('../components/Structure/Records/RecordsOfClientBooking'));

export default function recordsComponent(currentRecords, languages, handleModalOpen, handleModalClose, recordsType, handleSelectedWeekStats, userAuth) {
    let Records;
    switch (recordsType) {
        case "models":
            Records = RecordsOfModels;
            break;
        case "archive":
            Records = RecordsOfModelsArchived;
            break;
        case "users":
            Records = RecordsOfUsers;
            break;
        case "categories":
            Records = RecordsOfCategories;
            break;
        case "booking":
            Records = RecordsOfBookings;
            break;
        case "stat":
            Records = RecordsOfStats;
            break;
        case "clientBookings":
            Records = RecordsOfClientBookings;
            break;
        default:
            break;
    }
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Records data={currentRecords}
                handleModalOpen={handleModalOpen}
                handleModaClose={handleModalClose}
                languages={languages}
                handleSelectedWeekStats={handleSelectedWeekStats}
                userAuth={userAuth}
            />
        </Suspense>
    );
}