import React, { Component } from 'react';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export class PhoneNumberEntryList extends Component {
    constructor(props) {
        super(props);
        this.handleChangeNumberKeyDown = this.handleChangeNumberKeyDown.bind(this);
    }


    handleChangeNumberKeyDown(key) {
        if (key.key === 'Enter') {
            this.props.handleSelectedPhoneNumbers()
        }
    }

    cleanPhoneNumber(phoneObject) {
        if (phoneObject.phoneNumber.startsWith(phoneObject.dialCode)) {
            return phoneObject.phoneNumber.slice(phoneObject.dialCode.length);
        }
        else {
            return phoneObject.phoneNumber;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row" >
                    <div className="col-lg-6">
                        <div className="input-group mt-2 phone-entry-input-group">
                            <div className="form-control ">
                                <PhoneInput
                                    dropdownClass="text-dark"
                                    country={'fr'}
                                    value={this.props.currentPhoneNumber}
                                    onChange={this.props.handleChangeNumber}
                                    onKeyDown={this.handleChangeNumberKeyDown}
                                />
                            </div>
                            <button className="btn-with-img btn btn-success" type="button" onClick={this.props.handleSelectedPhoneNumbers}>
                                <img className="invert-color" src={require("../../Ressources/images/add_call.png")} alt="add_call" />
                            </button>
                        </div>
                        <div className="mt-3">
                            <ul className="list-group">
                                {this.props.phoneNumbers.map(item => {
                                    const phoneNumberCleaned = this.cleanPhoneNumber(item)
                                    return (
                                        <li key={item.phoneNumber} className="list-group-item">{'+'+item.dialCode + ' ' + phoneNumberCleaned}</li>
                                    )}
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 offset-lg-1 mt-4">
                        <p>Vous pouvez ajouter ou supprimer de la liste des numéros entrés manuellement.</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    /*
    <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Phone Number" aria-label="Recipient's username" aria-describedby="basic-addon2" />
        <span class="input-group-text">
            <button className="btn-with-img btn btn-light ">
                <img alt="add_call" src={require("../../Ressources/images/add_call.png")} />
            </button>
        </span>
    </div>
    */
}