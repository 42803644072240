import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Structure/Layout';
import useToken from './components/Login/useToken';
import './custom.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BasicLoading from './components/Structure/LoadingPage';

import { Login } from "./components/Login/Login";
import { Home } from "./components/Home";
import { Booking } from "./components/Booking/Booking";
import { User } from "./components/User/User";
import { UserList } from './components/User/UserList';
import { UserAdd } from './components/User/UserAdd';
import { UserLogo } from './components/User/UserLogo';
import { Model } from './components/Model/Model';
import { ModelAdd } from './components/Model/ModelAdd';
import { ModelEdit } from './components/Model/ModelEdit';
import { ModelList } from './components/Model/ModelList';
import { ModelArchive } from './components/Model/ModelArchive';
import { ModelCatAdd } from './components/Model/ModelCatAdd';
import { ModelCatList } from './components/Model/ModelCatList';
import { Stat } from "./components/Stat/Stat";
import { StatList } from "./components/Stat/StatList";
import { StatBookSearch } from "./components/Stat/StatBookSearch";
import { Schedule } from "./components/Schedule/Schedule";

function App() {
    const { token, loading, setToken } = useToken();

    if (loading) {
        return <BasicLoading />;
    }

    if (token != null) {
        return (
            <Layout>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="schedule" element={<Schedule />} />
                    <Route path="bookings" element={<Booking />} />
                    <Route path="user" element={<User />} >
                        <Route index element={<UserList />} />
                        <Route path="new" element={<UserAdd />} />
                        <Route path="logo" element={<UserLogo />} />
                    </Route>
                    <Route path="model" element={<Model />} >
                        <Route index element={<ModelList />} />
                        <Route path="new" element={<ModelAdd />} />
                        <Route path="edit" element={<ModelEdit />} />
                        <Route path="archive" element={<ModelArchive />} />
                        <Route path="new-category" element={<ModelCatAdd />} />
                        <Route path="categories" element={<ModelCatList />} />
                    </Route>
                    <Route path="stat" element={<Stat />} >
                        <Route index element={<StatList />} />
                        <Route path="search-booking" element={<StatBookSearch />} />
                    </Route>
                </Routes>
                <ToastContainer />
            </Layout>
        )
    }
    else {
        return (
            <React.Fragment>
                <Login setToken={setToken} />
                <ToastContainer />
            </React.Fragment>
        )
    }
}

export default App;