import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import * as modals from "../Structure/LayoutModals";
import DisplayList from '../Structure/DisplayList';

export class ModelCatList extends Component {
    static displayName = "ModelList";

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            loading: true,
            selectedCat: null,
            selectedCatAction: '',
            showModal: false,
            userAdmin: ''
        };
        this.fetchDataOnLoadAPI = this.fetchDataOnLoadAPI.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.updateCat = this.updateCat.bind(this);
        this.deleteCat = this.deleteCat.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.displayModal = this.displayModal.bind(this);
    }

    componentDidMount() {
        this.fetchDataOnLoadAPI();
    }

    handleChangeName(e) {
        let tempCat = this.state.selectedCat;
        tempCat.name = e.target.value;
        this.setState({ selectedCat: tempCat })
    }

    async fetchDataOnLoadAPI() {
        this.setState({
            categories: await utils.GetModelCategories(),
            userAdmin: await utils.GetUserAuthorization(),
            loading: false
        });
    }
    async deleteCat() {
        const updatedCategories = [...this.state.categories];
        const catModelToRemove = updatedCategories.findIndex(obj => obj.id === this.state.selectedCat.id);

        if (catModelToRemove !== -1) {
            if (await utils.DeleteModelCat(this.state.selectedCat.id)) {
                updatedCategories.splice(catModelToRemove, 1);

                this.setState({
                    categories: updatedCategories,
                    selectedCat: null,
                    showModal: false
                });
            }
        }
    }
    async updateCat() {
        const updatedCategories = [...this.state.categories];
        const catModelToUpdate = updatedCategories.findIndex(obj => obj.id === this.state.selectedCat.id);

        if (catModelToUpdate !== -1) {
            if (await utils.UpdateModelCat(this.state.selectedCat)) {
                updatedCategories[catModelToUpdate] = this.state.selectedCat;
                this.setState({
                    categories: updatedCategories,
                    selectedCat: null,
                    showModal: false,
                })
            }
        }
    }

    handleModalOpen(category, action) {
        let catJSON = JSON.stringify(category)
        this.setState({
            showModal: true,
            selectedCat: JSON.parse(catJSON),
            selectedCatAction: action
        })
    }
    handleModalClose() {
        this.setState({
            showModal: false,
            selectedCat: null,
            selectedCatAction: ''
        })
    }

    displayModal() {
        if (this.state.selectedCatAction === 'delete') {

            const modalTitle = <p>Supprimer une catégorie</p>;
            const modalBody = 
                <p>Êtes-vous sûr de supprimer cette catégorie:<br />
                    {this.state.selectedCat.name}</p>
            const modalFooter =
                <div>
                    <button className="btn btn-danger rounded-corners me-2" onClick={this.deleteCat}>Confirmer</button>
                    <button className="btn btn-secondary rounded-corners" onClick={this.handleModalClose}>Annuler</button>
                </div>
            return modals.displayLayoutModal(this.state.showModal, this.handleModalClose, modalTitle, modalBody, modalFooter)
        }
        if (this.state.selectedCatAction === 'update') {
            const modalTitle = <p>Modification d'une catégorie</p>;
            const modalBody = 
                <div className="form-group row mt-3">
                    <label htmlFor="inputName" className="col-sm-4 col-form-label">Nom de la catégorie</label>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" id="inputName"
                            value={this.state.selectedCat.name} onChange={this.handleChangeName} />
                    </div>
                </div>
            const modalFooter =
                <div>
                    <button className="btn btn-success rounded-corners me-2" onClick={this.updateCat}>Confirmer</button>
                    <button className="btn btn-primary rounded-corners" onClick={this.handleModalClose}>Annuler</button>
                </div>
            return modals.displayLayoutModal(this.state.showModal, this.handleModalClose, modalTitle, modalBody, modalFooter)
        }
    }

    render() {
        let modal = null;
        let content = null;

        this.state.selectedCat === null ? modal = null : modal = this.displayModal()

        if (this.state.loading) {
            content = <p><em>Loading...</em></p>
        }
        else {
            content = <DisplayList
                list={this.state.categories}
                recordsType={"categories"}
                handleModalOpen={this.handleModalOpen}
                languages={this.state.languages}
                pagination={true}
            />
        }

        return (
            <div>
                {content}
                {modal}
            </div>
        );
    }
}

