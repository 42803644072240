import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import Modal from "react-bootstrap/Modal";
import DisplayList from '../Structure/DisplayList';
import { StatDetails } from './StatDetails';
import { toast } from 'react-toastify';

export class StatList extends Component {
    static displayName = "StatList";

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isOpen: false,
            statistics: [],
            selectedYear: new Date().getFullYear(),
            statListHasChanged: false,
            monthYearStatStr: '',
            selectedWeekStats: [],
            selectedModalAction: '',
            selectedMonthList: []
        };
        this.fetchDataOnLoadAPI = this.fetchDataOnLoadAPI.bind(this);
        this.handleSelectedYear = this.handleSelectedYear.bind(this);
        this.handleSelectedWeekStats = this.handleSelectedWeekStats.bind(this);
        this.getStatistics = this.getStatistics.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalConfirm = this.handleModalConfirm.bind(this);
        this.displayModal = this.displayModal.bind(this);
    }

    componentDidMount() {
        this.fetchDataOnLoadAPI();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.statListHasChanged && prevState.selectedYear !== this.state.selectedYear) {
            let stats = await utils.GetStatisticsYear(this.state.selectedYear);

            if (stats.length == 0) {
                stats = [{
                    year: this.state.selectedYear,
                    month: null
                }];
            }
            this.setState({
                statistics: stats,
                loading: false,
                statListHasChanged: false
            })
        }
    }

    handleSelectedWeekStats(stats) {
        this.setState({ selectedWeekStats: stats });
    }

    handleModal(statList, monthStr, action) {
        this.setState({
            isOpen: true,
            monthYearStatStr: monthStr + ' ' + this.state.selectedYear,
            selectedMonthList: statList,
            selectedModalAction: action
        })
    }
    handleModalClose() {
        this.setState({
            isOpen: false,
            monthYearStatStr: '',
            selectedModalAction: '',
            selectedMonthList: null
        })
    }
    async handleModalConfirm() {
        this.setState({
            isOpen: false,
            monthYearStatStr: '',
            selectedModalAction: '',
            selectedMonthList: null
        })
        toast.promise(
            utils.DownloadMonthStats(this.state.selectedMonthList),
            {
                pending: 'Downloading file...',
                success: 'File downloaded successfully!',
                error: 'Error downloading file!',
            }
        );
    }

    async handleSelectedYear(year) {
        if (year !== this.state.selectedYear) {
            this.setState({
                selectedYear: year,
                statListHasChanged: true,
                loading:true
            });
        }
    }

    async fetchDataOnLoadAPI() {
        let stats = await this.getStatistics();

        if (stats.length === 0) {
            stats = [{
                year: this.state.selectedYear,
                month: null
            }];
        }

        this.setState({
            statistics: stats,
            loading: false
        });
    }

    async getStatistics() {
        return await utils.GetStatisticsYear(this.state.selectedYear);
    }

    displayModal() {
        if (this.state.selectedModalAction === 'downloadExcel') {
            return (
                <Modal show={this.state.isOpen} centered>
                    <Modal.Header>
                        <Modal.Title>Télécharger Statistiques</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        fichier excel pour les Statistiques de {this.state.monthYearStatStr }
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary rounded-corners" onClick={this.handleModalConfirm}>Confirmer</button>
                        <button className="btn btn-secondary rounded-corners" onClick={this.handleModalClose}>Annuler</button>
                    </Modal.Footer>
                </Modal>
            )
        }
    }

    render() {
        let content = null;
        let modal = null;

        if (this.state.selectedMonthList != null || this.state.selectedMonthList != undefined) {
            modal = this.state.selectedMonthList.length > 0 ? this.displayModal() : null;
        }

        if (this.state.loading) {
            content = <h5><em>Loading...</em></h5>
        }
        else {
            if (this.state.selectedWeekStats !== null && this.state.selectedWeekStats.length > 0) {
                content = <StatDetails
                    list={this.state.selectedWeekStats}
                    handleSelectedWeekStats={this.handleSelectedWeekStats}
                />
            }
            else {
                content = <DisplayList
                    list={this.state.statistics}
                    handleListChange={this.handleListChange}
                    recordsType={"stat"}
                    enableYearSelection={true}
                    selectedYear={this.state.selectedYear}
                    handleSelectedYear={this.handleSelectedYear}
                    handleSelectedWeekStats={this.handleSelectedWeekStats}
                    handleModalOpen={this.handleModal}
                />
            }
        }
        return (
            <div>
                {content}
                {modal}
            </div>
        );
    }
}