import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';

export class Model extends Component {
    static displayName = "Models";

    render() {
        return (
            <div>
                <h2>Administration des modèles</h2>
                <div className="row user-main" role="group" aria-label="Basic example">
                    <a href="/model/new" type="button" className="btn btn-primary col">Ajouter un modèle</a>
                    <a href="/model" type="button" className="btn btn-primary col">Liste des modèles</a>
                    <a href="/model/archive" type="button" className="btn btn-primary col">Modèles archivés</a>
                    <a href="/model/categories" type="button" className="btn btn-primary col">Liste des catégories</a>
                    <a href="/model/new-category" type="button" className="btn btn-primary col">Ajout Catégorie</a>
                </div>
                <Outlet />
            </div>
        );
    }
}
