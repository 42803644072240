import React, { Component } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
export class DatePicker extends Component {

    render() {
        return (
            <div className="mt-3 text-center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="Séléctionnez une date"
                        views={['year', 'month', 'day', 'hours']}
                        ampm={false}
                        value={this.props.selectedDateToSend}
                        onChange={this.props.handleChangeDateToSend}
                        format="DD/MM/YYYY"
                    />
                </LocalizationProvider>
            </div>
        )
    }
}