import React, { Component } from 'react';
import Pagination from '../Structure/Pagination';
import SearchResultData from './SearchResultData';
import recordsComponent from '../../Service/recordsImport';
import * as utils from '../../Service/utils';

export default class DisplayList extends Component {
    static displayName = "DisplayList";

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isOpen: false,
            list: [],
            userAuth: '',
            languages: props.languages,
            handleModalOpen: props.handleModalOpen,
            handleModalClose: props.handleModalClose,
            currentRecords: [],
            filteredListLength: 0,
            currentPage: 1,
            recordsPerPage: 15,
            listHasChanged: false,
            responseString: '',
            pageNumbers: [1],
            nbPages: 1
        };
        this.handleChangeRecordsPerPage = this.handleChangeRecordsPerPage.bind(this);
        this.handleChangeCurrentPage = this.handleChangeCurrentPage.bind(this);
        this.handleSelectedYear = this.handleSelectedYear.bind(this);
        this.handleList = this.handleList.bind(this);
        this.fetchAtLoad = this.fetchAtLoad.bind(this);
    }

    componentDidMount() {
        this.fetchAtLoad();
    }
    async fetchAtLoad() {
        this.setState({
            userAuth: await utils.GetUserAuthorization(),
        }, this.handleList);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.list !== this.props.list || this.state.listHasChanged) {
            this.handleList()
            this.setState({ listHasChanged: false })
        }
    }

    async handleSelectedYear(year) {
        if (year > new Date().getFullYear()) {
            year -= 1;
        }
        this.props.handleSelectedYear(year);
    }

    handleList() {
        let filteredList = [];
        if (this.props.list != null) {
            filteredList = this.props.list
        }

        const indexOfLastRecord = this.state.currentPage * this.state.recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - this.state.recordsPerPage;
        const pages = Math.ceil(filteredList.length / this.state.recordsPerPage);
        let records = null;

        if (this.props.enableYearSelection) {
            records = this.props.list;
        } else {
            records = filteredList.slice(indexOfFirstRecord, indexOfLastRecord);
        }

        this.setState({
            filteredListLength: filteredList.length,
            currentRecords: records,
            nbPages: pages,
            pageNumbers: [...Array(pages + 1).keys()].slice(1),
            listHasChanged: false
        });
    }


    handleChangeRecordsPerPage(e) {
        this.setState({
            recordsPerPage: e.target.value,
            currentPage: 1,
            listHasChanged: true
        })
    }
    handleChangeCurrentPage(page) {
        if (page < 1) { page = 1; }
        if (page > this.state.nbPages) { page = this.state.nbPages; }
        this.setState({
            currentPage: page,
            listHasChanged: true
        })
    }

    render() {
        let recordsContent = <p className="mt-3 ms-5"><em>pas de résultats</em></p>;
        let paginationContent = null;
        if (this.state.currentRecords.length > 0) {
            recordsContent = recordsComponent(
                this.state.currentRecords,
                this.state.languages,
                this.state.handleModalOpen,
                this.state.handleModalClose,
                this.props.recordsType,
                this.props.handleSelectedWeekStats,
                this.state.userAuth
            );
        }
        if (this.props.pagination) {
            paginationContent =
            <div className="card-footer">
                <Pagination
                    pageNumbers={this.state.pageNumbers}
                    currentPage={this.state.currentPage}
                    handleChangeCurrentPage={this.handleChangeCurrentPage}
                />
            </div>
        }
        return (
            <div className="card mb-5">
                <div className="card-header">
                    <SearchResultData
                        numberOfResult={this.state.filteredListLength}
                        handleChangeRecordsPerPage={this.handleChangeRecordsPerPage}
                        recordsPerPage={this.state.recordsPerPage}
                        enableYearSelection={this.props.enableYearSelection}
                        selectedYear={this.props.selectedYear}
                        handleSelectedYear={this.handleSelectedYear}
                    />
                </div>
                {recordsContent}
                {paginationContent}
            </div>
        )
    }
}