import { showToast } from './toast'
let token = GetCookie('session-id');
if (token === 'null' || token === 'undefined') {
    token = null
}

//USER CONTROLLER
export function GetUsers() {
    return fetch("api/smsuser/GetUsers",
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                HandleResponseErrors(response);
            }
        });
}

export function PostUser(formDataUser) {
    return fetch("api/smsuser/PostUser",
        {
            method: 'POST',
            body: formDataUser,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.ok) {
                showToast("message a modifié dans utils.js", response.status);
                return true;
            } else {
                HandleResponseErrors(response);
            }
        });
}

export function DeleteUser(idUser) {
    return fetch("api/smsuser/DeleteUser?idUser=" + idUser,
    {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            showToast("L'utilisateur a bien été supprimé", response.status);
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function UploadImage(formData) {
    return fetch("api/smsuser/UploadImage",
    {
        method: 'POST',
        body: formData,
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            showToast("message a modifié dans utils.js", response.status);
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function CheckLogoExists(idOrg) {
    return fetch("api/smsuser/CheckLogoExists?idOrga=" + idOrg,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
        }
    });
}




//SMS MODEL CONTROLLER
export function GetModels(archive) {
    return fetch("api/model/GetModels?archive=" + archive,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function GetModel(id) {
    return fetch("api/model/GetModel?idModel=" + id,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function PostModel(formDataModel) {
    return fetch("api/model/PostModel",
    {
        method: 'POST',
        body: formDataModel,
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            showToast("Modèle crée avec succès", response.status);
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function UpdateModel(formData) {
    return fetch("api/model/UpdateModel",
    {
        method: 'PUT',
        body: formData,
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            showToast("Modèle modifié avec succès", response.status);
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function DeleteModel(modelId) {
    return fetch("api/model/DeleteModel?modelId=" + modelId,
    {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            showToast("Le modèle a été supprimée", response.status);
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function DeleteModelLangue(idModelLangu) {
    return fetch("api/model/DeleteModelLangue?idModelLanguage=" + idModelLangu,
    {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            showToast("La traduction associée a été supprimée", response.status);
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}

//SCHEDULE CONTROLLER
export function SetScheduledMessage(obj) {
    return fetch("api/sending/SetScheduledMessage",
    {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(obj)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function SendMessage() {
    return fetch("api/sending/SendMessage",
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
        }
    });
}


//LOGIN CONTROLLER
export function loginUser(formData) {
    return fetch('api/login/LoginUser', 
    {
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: formData
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
            return false;
        }
    });
}

export function firstLoginUser(formData) {
    return fetch('api/login/FirstLoginUser',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                HandleResponseErrors(response);
                return false;
            }
        });
}

export function VerifyToken(tokenToCheck) {
    return fetch('api/login/VerifyToken', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${tokenToCheck}`
        }
    })
    .then(response => {
        if (response.ok) {
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function GetCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function SetCookie(key, value) {
    return fetch('api/login/LoginUser?key='+key+'&cookieValue='+value,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
    .then(response => {
        if (!response.ok) {
            HandleResponseErrors(response);
        }
    });
}

export function SignOut() {
    return fetch('api/login/SignOut', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}



//BOOKING CONTROLLER
export function GetBookings(formData) {
    return fetch("api/booking/GetBookings",
    {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: formData,
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
        }
    });
}

//STATISTIC CONTROLLER
export function GetStatisticsYear(year) {
    return fetch("api/statistic/GetStatisticsYear?year=" + year,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function GetStatisticsMonth(month) {
    return fetch("api/statistic/GetStatisticsMonth?month=" + month,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
        }
    });
}


export function DownloadMonthStats(list) {
    return new Promise((resolve, reject) => {
        fetch("api/Statistic/DownloadMonthStats",
            {
                method: 'POST',
                body: JSON.stringify(list),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                if (response.ok) {
                    var fileNameStartStr = response.headers.get('Content-Disposition').split('filename="')[1];
                    var filenameStr = fileNameStartStr.split('";')[0];

                    response.blob().then(function (blob) {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filenameStr;
                        link.click();
                        resolve();
                    });
                } else {
                    HandleResponseErrors(response);
                }
            });
    });
}


//MODEL CATEGORY CONTROLLER
export function GetModelCategories() {
    return fetch("api/modelcategory/GetModelCategories",
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
        }
    });
}


export function PostModelCat(categoryName) {
    return fetch("api/modelcategory/PostModelCat?categoryName=" + categoryName,
    {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            showToast("Catégorie " + categoryName + " créée.", response.status);
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function UpdateModelCat(categoryObj) {
    return fetch("api/modelcategory/UpdateModelCat", {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(categoryObj)
    })
    .then(response => {
        if (response.ok) {
            showToast("Catégorie modifiée", response.status);
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function DeleteModelCat(selectedCategoryId) {
    return fetch("api/modelcategory/DeleteModelCat?categoryId=" + selectedCategoryId,
    {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            showToast("Catégorie supprimée.", response.status);
            return true;
        } else {
            HandleResponseErrors(response);
        }
    });
}


//SMS CONTROLLER (general use)
export function GetLanguages() {
    return fetch("api/Sms/GetLanguages", {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`        }
    })
    .then(response => {
        if (response.ok) {
            return response.json(); 
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function GetOrganizations() {
    return fetch("api/Sms/GetOrganizations",
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json(); 
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function GetOrganizationName() {
    return fetch("api/Sms/GetOrganizationName",
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json(); 
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function SetOrganization(id) {
    return fetch("api/Sms/SetOrganization?orgId=" + id,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
    .then(response => {
        if (!response.ok) {
            HandleResponseErrors(response);
        }
    });
}

export function GetUserAuthorization() {
    return fetch("api/Sms/GetUserAuthorization",
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function GetUserOrg() {
    return fetch("api/Sms/GetUserOrg",
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json(); 
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function GetExempleChampAuto(content) {
    return fetch("api/Sms/GetExempleChampAuto",
    {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(content),
    })
    .then(response => {
        if (response.ok) {
            return response.json(); 
        } else {
            HandleResponseErrors(response);
        }
    });
}

export function GetTranslatedString(key) {
    return fetch("api/Sms/GetTranslatedString?key=" + key,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                HandleResponseErrors(response);
            }
        });
}

export function GetFlagById(idFlag) {
    const ukFlag = require("../Ressources/images/languages/uk.png");
    const germanyFlag = require("../Ressources/images/languages/germany.png");
    const franceFlag = require("../Ressources/images/languages/france.png");
    const italyFlag = require("../Ressources/images/languages/italy.png");

    switch (idFlag) {
        case 2:
            return ukFlag;
        case 3:
            return germanyFlag;
        case 6:
            return franceFlag;
        case 8:
            return italyFlag;
        default:
            return null;
    }
}

function HandleResponseErrors(response) {
    response.text().then((text) => {
        const obj = JSON.parse(text);
        let msgErr = undefined
        msgErr = obj.error;
        if (obj.error == null) {
            msgErr = obj.title;
            if (obj.title == null) {
                msgErr = obj;
            }
        }
        if (process.env.NODE_ENV === 'production' && response.status > 499 && response.status <= 600) {
            msgErr = "hidden error message"
        }
        showToast(msgErr, response.status);
        throw new Error(`${response.status}\n${msgErr}`);
    })
}

//TestToast()
function TestToast() {
    showToast('good', 200);
    showToast('warning! Ton mot de passe fcn_en_ligue2 est trop simple.', 300);
    showToast("ERROR Lorem Ipsum is simply dummy text of the printing and typesetting industry." +
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s," +
        " when an unknown printer took a galley of type and scrambled it to make a type specimen book." +
        "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged." +
        "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, " +
        "and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.", 500);
}