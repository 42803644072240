import { toast } from 'react-toastify';

export const showToast = (message, status) => {
    const truncatedMessage = message.length > 600 ? message.slice(0, 600) + '...' : message;

    if (status > 199 && status < 300) {
        toast.success(message);
    }
    else if (status > 299 && status <= 400) {
        toast.warning('Warning ' + status + ': ' + truncatedMessage, { autoClose: 10000, draggable: false });
    }
    else if (process.env.NODE_ENV !== 'production') {
        toast.error('Error ' + status + ': ' + truncatedMessage, { autoClose: 30000, closeOnClick: false, draggable: false, bodyClassName:'' });
    }
    else {
        toast.error('Error ' + status + '(error message hidden)', { autoClose: 30000, closeOnClick: false, draggable: false });
    }
};
