import Modal from "react-bootstrap/Modal";

export function displayLayoutModal(showModal, handleModalClose, modalTitle, modalBody, modalFooter) {
    return (
        <Modal show={showModal} onHide={handleModalClose} centered >
            <Modal.Header>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalBody}</Modal.Body>
            <Modal.Footer>{modalFooter}</Modal.Footer>
        </Modal>
    )
}

export function displayLayoutModalNoFooter(showModal, handleModalClose, modalTitle, modalBody) {
    return (
        <Modal show={showModal} onHide={handleModalClose} centered >
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalBody}</Modal.Body>
        </Modal>
    )
}