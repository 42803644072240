import React, { Component } from 'react';
import * as utils from '../../Service/utils';
import * as modals from "../Structure/LayoutModals";
import { DatePicker } from './DatePicker';
import { DurationPicker } from './DurationPicker';
import { BookingSearch } from '../Booking/BookingSearch';
import { PhoneNumberEntryList } from './PhoneNumberEntryList';
import Form from 'react-bootstrap/Form';


export class Schedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedModel: {},
            modelList: [],
            selectedBookings: [],
            phoneNumbers: [],
            currentPhoneNumber: '',
            currentPhoneDialCode: '',
            currentCountryCode: '',
            whoTypeReceiver: '',
            whenPickerReceiver: '',
            modelSelectorType: '',
            messageTyped: '',
            selectedFromWhenType: 0,
            selectedDateToSend: null,
            duration: {
                month: 0,
                day: 0,
                hour: 0
            },
            durationStr: '',
            showModal: false,

            monthsOptions: null,
            daysOptions: null,
            hoursOptions: null,
            organizationName: ''
        };
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleTextModel = this.handleTextModel.bind(this);
        this.changeSendType = this.changeSendType.bind(this);
        this.handleSelectedBookings = this.handleSelectedBookings.bind(this);
        this.handleSelectedPhoneNumbers = this.handleSelectedPhoneNumbers.bind(this);
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.handleDurationChange = this.handleDurationChange.bind(this);
        this.changeWhenSelectorType = this.changeWhenSelectorType.bind(this);
        this.changeModelSelectorType = this.changeModelSelectorType.bind(this);
        this.handleSelectedFromWhenType = this.handleSelectedFromWhenType.bind(this);
        this.makeValidationModalBody = this.makeValidationModalBody.bind(this);
        this.handleChangeDateToSend = this.handleChangeDateToSend.bind(this);
        this.getMainPanelContent = this.getMainPanelContent.bind(this);
        this.getModelSelectorContent = this.getModelSelectorContent.bind(this);
        this.handleMessageTypedChange = this.handleMessageTypedChange.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.sendTestTextMessage = this.sendTestTextMessage.bind(this);
        
    }

    async componentDidMount() {

        const months = [];
        for (let i = 0; i <= 12; i++) {
            months.push(<option key={i} value={i+'m'}>{i}</option>);
        }

        const days = [];
        for (let i = 0; i <= 31; i++) {
            days.push(<option key={i} value={i+'d'}>{i}</option>);
        }

        const hours = [];
        for (let i = 0; i <= 23; i++) {
            hours.push(<option key={i} value={i+'h'}>{i}</option>);
        }

        const modelList = await utils.GetModels(false);
        if (modelList != null) {
            this.setState({
                modelList: modelList,
                selectedModel: modelList[0]
            })
        }
        this.setState({
            monthsOptions: months,
            daysOptions: days,
            hoursOptions: hours,
            organizationName: await utils.GetOrganizationName()
        })
    }

    handleModalOpen() {
        this.setState({ showModal: true })
    }
    handleModalClose() {
        this.setState({ showModal: false })
    }
    handleTextModel(e) {
        this.setState({ selectedModel: e.target.value})
    }
    changeSendType(e) {
        this.setState({ whoTypeReceiver: Number(e.target.value) })
    }
    changeWhenSelectorType(e) {
        this.setState({ whenPickerReceiver: Number(e.target.value) })
    }
    changeModelSelectorType(e) {
        this.setState({ modelSelectorType: Number(e.target.value) })
    }
    handleSelectedFromWhenType(e) {
        this.setState({ selectedFromWhenType: Number(e.target.value) })
    }
    handleChangeDateToSend(dateData) {
        this.setState({ selectedDateToSend: dateData })
    }
    handleMessageTypedChange(e) {
        this.setState({ messageTyped: e.target.value })
    }

    handleDurationChange(key, val) {
        if (isNaN(val) || val == null) {
            val = 0;
        }
        if (val < 0 || val > 50) {
            val = 0;
        }
        this.setState(
            prevState => {
                let updatedDuration = {};

                switch (key) {
                    case 'months':
                        updatedDuration = { ...prevState.duration, month: val };
                        break;
                    case 'days':
                        updatedDuration = { ...prevState.duration, day: val };
                        break;
                    case 'hours':
                        updatedDuration = { ...prevState.duration, hour: val };
                        break;
                    default:
                        updatedDuration = {
                            month: 0,
                            day: 0,
                            hour: 0
                        };
                        break;
                }

                return { duration: updatedDuration };
            },
            () => {
                this.setState({
                    durationStr: this.makeDurationString()
                })
            }
        );
    }


    makeDurationString() {
        let durationString = '';
        if (this.state.duration.month === 0 && this.state.duration.day === 0 && this.state.duration.hour === 0) {
            durationString = '';
        }

        if (this.state.duration.month !== 0) {
            durationString = this.state.duration.month + ' mois';
        }

        if (this.state.duration.day !== 0) {
            durationString += (durationString !== '') ? ', ' : '';
            durationString += this.state.duration.day + ' jour(s)';
        }

        if (this.state.duration.hour !== 0) {
            durationString += (durationString !== '') ? ', ' : '';
            durationString += this.state.duration.hour + ' heure(s)';
        }

        return <small>{durationString}</small>;
    }

    handleSelectedBookings(e) {
        let selectedBookings = this.state.selectedBookings;
        let value = e.target.value;
        let index = selectedBookings.indexOf(value);
        
        if (index !== -1) {
            selectedBookings.splice(index, 1);
        } else {
            selectedBookings.push(value);
        }
        this.setState({ selectedBookings: selectedBookings })
    }

    handleSelectedPhoneNumbers() {
        const { phoneNumbers, currentPhoneNumber, currentCountryCode } = this.state;
        const phoneNumberObject = {
            phoneNumber: currentPhoneNumber,
            countryCode: currentCountryCode,
            firstName: 'unknown',
            lastName: 'unknown'
        };

        this.setState({
            phoneNumbers: [...phoneNumbers, phoneNumberObject],
            currentPhoneNumber: '',
            currentCountryCode: ''
        });
    }
    handleChangeNumber(phoneNumber, countryInfo) {
        console.log(countryInfo)
        this.setState({
            currentPhoneNumber: phoneNumber,
            currentPhoneDialCode: countryInfo.dialCode,
            currentCountryCode: countryInfo.countryCode
        })
    }
    async sendTestTextMessage() {
        await utils.SendMessage();
    }

    makeValidationModalBody() {
        let modalBodyContent = null;
        if (this.state.modelSelectorType === 0) {
            modalBodyContent = (<p>Vous avez choisi le modèle <b>{this.state.selectedModel.modelName}</b></p>)
        } else if (this.state.modelSelectorType === 2) {
            modalBodyContent = (
                <div>
                    <p>Message entré directement: </p>
                    <div className="bubble-container">
                        <div className="bubble mine">
                            {this.state.messageTyped}
                        </div>
                    </div>
                    <ul>
                        <li>Le message sera envoyé tel quel.</li>
                        <li>Il ne sera pas enregistré.</li>
                    </ul>
                </div>);
        }

        switch (this.state.whoTypeReceiver) {
            case 0:
                modalBodyContent = (
                    <>
                        {modalBodyContent}
                        <div>
                            <p>Nombre de clients séléctionnés: <b>{this.state.selectedBookings.length}</b></p>
                        </div>
                    </>
                );
                break;
            case 1:
                modalBodyContent = (
                    <>
                        {modalBodyContent}
                        <div>
                            <p>nombre de numéro séléctionnés: <b>{this.state.phoneNumbers ? this.state.phoneNumbers.length : '0'}</b></p>
                        </div>
                    </>
                );
                break;
            case 2:
                modalBodyContent = (
                    <>
                        {modalBodyContent}
                        <div>
                            <p>Tous les clients avec une réservation en cours sont sélectionnés</p>
                        </div>
                    </>
                );
                break;
            default:
                break;
        }
        
        if (this.state.whenPickerReceiver === 0) {
            if (this.state.selectedDateToSend != null) {
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                const localDate = this.state.selectedDateToSend.$d.toLocaleString('fr-FR', options)

                modalBodyContent = (
                    <>
                        {modalBodyContent}
                        <div>
                            <p>Le SMS sera envoyé le: <b>{localDate}</b></p>
                        </div>
                    </>
                );
            }
            else {
                modalBodyContent = (
                    <>
                        {modalBodyContent}
                        <div>
                            <p>Aucune date n'a été définie pour l'envoi</p>
                        </div>
                    </>
                );
            }
        }
        else if (this.state.whenPickerReceiver === 1) {
            modalBodyContent = (
                <>
                    {modalBodyContent}
                    <div>
                        <p>Le SMS sera envoyé dans {this.state.durationStr} </p>
                    </div>
                </>
            );
            switch (this.state.selectedFromWhenType) {
                case 0:
                    modalBodyContent = (
                        <>
                            {modalBodyContent}
                            <p> après le jour d'arrivée du client </p>
                        </>
                    );
                    break;
                case 1:
                    modalBodyContent = (
                        <>
                            {modalBodyContent}
                            <p> après le jour de départ du client </p>
                        </>
                    );
                    break;
                case 2:
                    modalBodyContent = (
                        <>
                            {modalBodyContent}
                            <p> à partir de maintenant </p>
                        </>
                    );
                    break;
                default:
                    break;
            }
        }
        else if (this.state.whenPickerReceiver === 2) {
            modalBodyContent = (
                <>
                    {modalBodyContent}
                    <div>
                        <p>Le message sera envoyé maintenant.</p>
                    </div>
                </>
            );
        }

        return modalBodyContent
    }

    getModelSelectorContent() {
        let modelSelector = null;
        switch (this.state.modelSelectorType) {
            case 0:
                modelSelector = 
                    <select className="form-select" value={this.state.selectedModel}
                        onChange={this.handleTextModel} >
                        {this.state.modelList.map(item =>
                            <option key={item.id} value={item.id}>{item.id} - {item.modelName}</option>
                        )}
                    </select>
                break;
            case 1:
                modelSelector =
                    <a className="btn btn-primary" href="/model/new" >Créer un nouveau modèle</a>
                break;
            case 2:
                modelSelector =
                    <div>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message à envoyer: </Form.Label>
                            <Form.Control as="textarea" rows={3} value={this.state.messageTyped} onChange={this.handleMessageTypedChange} />
                        </Form.Group>
                    </div>
                break;
            default:
                break;
        }
        return modelSelector;
    }

    getMainPanelContent() {
        let whereContentReceiver = null;
        switch (this.state.whenPickerReceiver) {
            case 0:
                whereContentReceiver = <DatePicker
                    handleChangeDateToSend={this.handleChangeDateToSend}
                    selectedDateToSend={this.state.selectedDateToSend}
                />
                break;
            case 1:
                whereContentReceiver = <DurationPicker
                    duration={this.state.duration}
                    durationString={this.state.durationStr}
                    handleDurationChange={this.handleDurationChange}
                    selectedFromWhenType={this.state.selectedFromWhenType}
                    handleSelectedFromWhenType={this.handleSelectedFromWhenType}
                />
                break;
            case 2:
                whereContentReceiver = <p>Le message sera envoyé à partir de maintenant.</p>
                break;
            default:
                whereContentReceiver = <div className="mt-2"><em>Sélectionnez quand vous voulez envoyer le SMS.</em></div>
                break;
        }
        return whereContentReceiver;
    }

    async handleConfirmation() {
        const modelInfoObj = {
            TextModelType: this.state.modelSelectorType,
            Message: this.state.messageTyped,
            ModelId: this.state.selectedModel.id
        }

        const toWhoRequestInfoObj = {
            ToWhoType: this.state.whoTypeReceiver,
            PhoneNumbers: this.state.phoneNumbers,
            UserIds: this.state.selectedBookings 
        }
        const whenRequestInfoObj = {
            WhenType: this.state.whenPickerReceiver,
            FromWhenType: this.state.selectedFromWhenType,
            SendingDate: this.state.selectedDateToSend ? this.state.selectedDateToSend.$d : new Date(),
            Duration: this.state.duration
        }

        const sendingMessageRequestObj = {
            ModelInfo: modelInfoObj,
            ToWhoRequestInfo: toWhoRequestInfoObj,
            WhenRequestInfo: whenRequestInfoObj,
            OrganizationName: this.organizationName
        }

        const response = await utils.SetScheduledMessage(sendingMessageRequestObj)
    }

    render() {
        let receiver = null
        switch (this.state.whoTypeReceiver) {
            case 0:
                receiver = <BookingSearch action="select" handleSelectedBookings={this.handleSelectedBookings} />
                break;
            case 1:
                receiver = <PhoneNumberEntryList handleSelectedPhoneNumbers={this.handleSelectedPhoneNumbers}
                    handleChangeNumber={this.handleChangeNumber} phoneNumbers={this.state.phoneNumbers} />
                break;
            case 2:
                receiver = <div className="mt-5"><em>Sélection de tous les clients ayant une réservation en cours</em></div>
                break;
            default:
                receiver = <div className="mt-5"><em>Sélectionnez à qui vous voulez envoyer le SMS.</em></div>
        }

        const smsModelSelector = this.getModelSelectorContent();
        const whereContentReceiver = this.getMainPanelContent();

        const modalTitle = <p>Valider la planification SMS</p>
        const modalBody = this.makeValidationModalBody()
        const modalFooter =
            <div>
                <button className="btn btn-primary rounded-corners me-2" onClick={this.handleConfirmation }>Confirmer</button>
                <button className="btn btn-secondary rounded-corners" onClick={this.handleModalClose}>Annuler</button>
            </div>

        const modal = modals.displayLayoutModal(this.state.showModal, this.handleModalClose, modalTitle, modalBody, modalFooter);

        return (
            <div>
                <div>
                    Programmer un envoi de SMS automatisé à un groupe de client et à un temps défini.
                </div>
                <div className="row mt-4">
                    <div className="col-sm-3">
                        <div>
                            <h5>Modèle de SMS:</h5>
                            <Form>
                                <Form.Check
                                    label="Choisir un modèle"
                                    name="group1"
                                    type="radio"
                                    id={`radio-model-1`}
                                    onChange={this.changeModelSelectorType}
                                    value={0}
                                />
                                <Form.Check
                                    label="Créer un nouveau modèle"
                                    name="group1"
                                    type="radio"
                                    id={`radio-model-2`}
                                    onChange={this.changeModelSelectorType}
                                    value={1}
                                />
                                <Form.Check
                                    label="Taper directement un message"
                                    name="group1"
                                    type="radio"
                                    id={`radio-model-3`}
                                    onChange={this.changeModelSelectorType}
                                    value={2}
                                />
                            </Form>
                            {smsModelSelector }
                        </div>
                        <div className="mt-4">
                            <h5>À qui?</h5>
                            <div className=" mt-2">
                                <Form>
                                    <Form.Check
                                        label="Client / Réservation"
                                        name="group1"
                                        type="radio"
                                        id={`radio-who-1`}
                                        onChange={this.changeSendType}
                                        value={0}
                                    />
                                    <Form.Check
                                        label="Entrée manuelle"
                                        name="group1"
                                        type="radio"
                                        id={`radio-who-2`}
                                        onChange={this.changeSendType}
                                        value={1}
                                    />
                                    <Form.Check
                                        label="Tous"
                                        name="group1"
                                        type="radio"
                                        id={`radio-who-3`}
                                        onChange={this.changeSendType}
                                        value={2}
                                    />
                                </Form>
                            </div>
                        </div>
                        <div className="mt-4 ">
                            <h5>Quand?</h5>
                            <Form>
                                <Form.Check
                                    inline
                                    label="Date"
                                    name="group1"
                                    type="radio"
                                    id={`radio-where-1`}
                                    onChange={this.changeWhenSelectorType}
                                    value={0}
                                />
                                <Form.Check
                                    inline
                                    label="Durée"
                                    name="group1"
                                    type="radio"
                                    id={`radio-where-2`}
                                    onChange={this.changeWhenSelectorType}
                                    value={1}
                                />
                                <Form.Check
                                    inline
                                    label="Maintenant"
                                    name="group1"
                                    type="radio"
                                    id={`radio-where-3`}
                                    onChange={this.changeWhenSelectorType}
                                    value={2}
                                />
                            </Form>
                        </div>
                        {whereContentReceiver}

                        <div className="row mt-4">
                            <button type="button" onClick={this.handleModalOpen} className="homeBtns col-8 offset-2 btn btn-primary">Valider</button>
                        </div>
                        
                        {/*<button type="button" className="btn btn-warning mt-5" onClick={this.sendTestTextMessage}>envoi test</button>*/}
                    </div>
                    <div className="col card text-center schedule-table-parent pt-2">
                        { receiver }
                    </div>
                </div>
                {modal}
            </div>
        )
    }

}